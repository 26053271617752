'use strict';

import $ from 'jquery';

Dropzone.autoDiscover = false;
import 'dropzone';

const gallery = {
  init() {
    const _token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const challengeId = document.querySelectorAll('.js-challenge-id').value || 0;

    new Dropzone('#myDropzone', {
      url: `${window.location.origin}/acoes-disponiveis/${challengeId}/relatorio/lista-de-presenca/upload-fotos`,
      paramName: 'gallery',
      maxFilesize: 10,
      maxFiles: 50,
      acceptedFiles: 'image/*',
      params: {
        _token: _token,
      },
      addRemoveLinks: true,
      dictDefaultMessage: 'Selecione as imagens que deseja enviar',
      dictCancelUpload: 'Cancelar o envio',
      dictRemoveFile: 'Remover',
      dictMaxFilesExceeded: 'Você já adicionou uma foto',
      dictFileTooBig: 'Arquivo muito grande',
      dictInvalidFileType: 'Este arquivo não é uma imagem',
      init: function() {
        this.on('success', function(file, response) {
          $('.added_photos').append('<input type="hidden" data-name="' + file.name + '" name="gallery[]" value="' + response + '"/>');
        });
        this.on('removedfile', function(file, response) {
          $("input[name='gallery[]']").each(function() {
            if ($(this).attr('data-name') == file.name) {
              $(this).remove();
            }
          });
        });
      },
    });
  },
};

export default gallery;
