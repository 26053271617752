'use strict';

import challengesForm from '../../modules/challengesForm';
import { stateCity } from '../../modules/select';

const edit = {
  init() {
    challengesForm.init();
    stateCity();
  },
};

const _this = edit;

export default _this;
