'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const activities = {
  init() {
    $('.js-toggle').on('click', function() {
      $(this)
        .next('.slide-container')
        .slideToggle();
    });

    let $inputs = $('#donation input');

    $('.custom-control-input').on('change', function(e) {
      let type = $("input[name='type']:checked").val();

      if (this.value == 1) {
        $('#' + $(this).attr('data-id')).removeClass('u-display--none');
        $inputs.each(function(idx, el) {
          $(el).attr('required', true);
        });
      } else {
        $('#' + $(this).attr('data-id')).addClass('u-display--none');
        $('#' + $(this).attr('data-id'))
          .find('input:text')
          .val('');
        $inputs.each(function(idx, el) {
          $(el).attr('required', false);
        });
      }
    });

    $('.js-form-activities').on('submit', function(e) {
      e.preventDefault();
      if ($("input[name='make_donation']:checked").val() == 1) {
        let submitForm = false;
        var count = 0;

        $('input').each(function(index, el) {
          if (el.value.length > 0) {
            count++;
          }
        });

        if (count <= 4) {
          Swal.fire({
            icon: 'error',
            title: 'Atenção!',
            text: 'Preencha pelo menos 1 item de doação!',
          });
          submitForm = false;
          return false;
        }

        submitForm = true;

        if (submitForm) {
          e.target.submit();
        }
      } else {
        let submitForm = false;
        $('[required]').each(function(index, el) {
          if (el.value.length <= 0) {
            Swal.fire({
              icon: 'error',
              title: 'Atenção!',
              text: 'Preencha os campos corretamente',
            });
            submitForm = false;
            return false;
          }
          submitForm = true;
        });
        if (submitForm) {
          e.target.submit();
        }
      }
    });
  },
};

export default activities;
