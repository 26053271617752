'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

// Inscreve o usuário selecionado
const subscribe = (challenge_id, user_id) => {
  disableButton('Adicionando voluntário, aguarde ...');

  $.ajax({
    type: 'POST',
    url: '/efetuar-inscricao',
    data: {
      challenge_id,
      user_id,
      _token: $('meta[name="csrf-token"]').attr('content'),
    },
    dataType: 'json',
    success: function (data) {
      if (data.type === 'success') {
        $('#subscribes-table').append(data.html);

        if (data.count) {
          $('.js-counter').html(data.count);
        }

        if (data.free_vacancies) {
          $('.js-free-vacancies').html(data.free_vacancies);
        }

        $('.js-data-user').val('').trigger('change');
      }

      enableButton('Incluir voluntário na ação');

      Swal.fire(data.title, data.message, data.type);
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      enableButton('Incluir voluntário na ação');
    },
  });
};

// Desabilita o botão de inclusão
const disableButton = text => {
  $('.js-include-volunteer').prop('disabled', true);
  $('.js-include-volunteer').text(text);
};

// Habilita o botão de inclusão
const enableButton = text => {
  $('.js-include-volunteer').prop('disabled', false);
  $('.js-include-volunteer').text(text);
};

// Abre o formulário de inscrição
const callForm = (challenge_id, user_id) => {
  $.ajax({
    type: 'POST',
    url: '/formulario-de-acao/',
    data: {
      challenge_id,
      user_id,
      _token: $('meta[name="csrf-token"]').attr('content'),
    },
    dataType: 'json',
    success: function (data) {
      if (data.success) {
        $('body').append(data.html);
      } else {
        Swal.fire(data.title, data.message, data.type);
      }
    },
  });
};

const show = {
  init() {
    $(document).on('click', '.js-include-volunteer', function (e) {
      e.preventDefault();

      const challenge_id = $(this).attr('data-challenge');
      const user_id = $('.js-data-user').val();
      const has_form = !!$('[name="has_form"]').length;

      if (has_form) {
        callForm(challenge_id, user_id);
      } else {
        subscribe(challenge_id, user_id);
      }
    });

    // Abre campos dependentes
    $(document).on('change', '.js-select-change', function () {
      $(this).next('[data-type="hidden"]').hide().find('input').val('').attr('required', false);

      let $block = $('#block_' + $(this).val());

      if ($block) {
        if (!$block.is(':visible')) {
          $block.show().find('input').val('').attr('required', true);
        }
      }
    });

    // Submit do formulário
    $(document).on('submit', '.js-form-modal', function (e) {
      e.preventDefault();

      $.ajax({
        type: 'POST',
        url: '/formulario-de-acao/enviar',
        data: $(this).serialize(),
        dataType: 'json',
        success: function (data) {
          if (data.success) {
            $('#modal-form').remove();
            subscribe(data.challenge_id, data.user_id);
          }
        },
      });
    });

    $(document).on('click', '.js-include-volunteer-external', function (e) {
      e.preventDefault();

      function isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
      }

      if (isEmail($('.js-data-external').val())) {
        $('.js-include-volunteer-external').prop('disabled', true);
        $('.js-include-volunteer-external').text('Enviando convite, aguarde ...');

        var post = {};

        post.challenge_id = $(this).attr('data-challenge');
        post.email = $('.js-data-external').val();
        post._token = $('meta[name="csrf-token"]').attr('content');

        var url = '/convidar-voluntario';

        $.ajax({
          type: 'POST',
          url: url,
          data: post,
          dataType: 'json',
          success: function (data) {
            $('.js-data-external').val('');
            $('.js-include-volunteer-external').prop('disabled', false);
            $('.js-include-volunteer-external').text('ENVIAR CONVITE');
            $('#invites-table').append(data.html);
            $('.js-subscribes-counter').html(data.counter);
            Swal.fire(data.title, data.message, data.type);
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            $('.js-include-volunteer-external').prop('disabled', false);
            $('.js-include-volunteer-external').text('ENVIAR CONVITE');
          },
        });
      } else {
        Swal.fire('Atenção', 'Informe um e-mail válido!', 'error');
      }

      return false;
    });

    function formatOptions(opt) {
      var $opt = $('<div class="u-size-16 u-m-b-10">' + opt.text + '</div>');
      return $opt;
    }

    fetch(`${window.location.origin}/get/users`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('input[name="_token"]').value,
      },
    })
      .then(response => (response.ok ? response.json() : console.log('Network response was not ok.')))
      .then(data => {
        $('.js-data-user').select2({
          theme: 'classic',
          placeholder: 'Selecione o voluntário',
          data: JSON.parse(data.users),
          templateSelection: formatOptions,
          templateResult: formatOptions,
        });
      })
      .catch(error => {
        console.log('There has been a problem with your fetch operation: ' + error.message);
      });
  },
};

export default show;
