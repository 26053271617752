import React, { useState, useEffect } from 'react';
import style from './style.module.scss';

function Modal({ modalContent, setModalContent }) {
  return (
    <>
      {modalContent ? (
        <>
          <div
            className={style.darkbox}
            onClick={e => setModalContent(null)}
          />
          <div className={style.modal}>{modalContent}</div>
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default Modal;
