'use strict';

import $ from 'jquery';
import 'daterangepicker';
import 'moment';

const dateRange = {
  init() {
    var prev_date = new Date();

    $('.js-date').daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: 'Limpar',
        applyLabel: 'Aplicar',
        customRangeLabel: 'Custom',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      },
    });

    $('.js-date').on('apply.daterangepicker', function(ev, picker) {
      $('input[name="start_at"]').val(picker.startDate.format('DD/MM/YYYY'));
      $('input[name="end_at"]').val(picker.endDate.format('DD/MM/YYYY'));
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });

    $('.js-date').on('cancel.daterangepicker', function(ev, picker) {
      $('input[name="filter[start_at]"]').val('');
      $('input[name="filter[end_at]"]').val('');
      $(this).val('');
    });
  },
};

const _this = dateRange;

export default _this;
