'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const common = {
  Validate() {
    var password = document.getElementById('password_user').value;
    var confirmPassword = document.getElementById('password_confirmation').value;

    if (password != confirmPassword) {
      return false;
    }

    return true;
  },

  bindActions() {
    $('#password_confirmation').on('keyup', function () {
      if (!_this.Validate()) {
        $('#js-diff-passwords').removeClass('u-display--none');
      } else {
        $('#js-diff-passwords').addClass('u-display--none');
      }
    });
  },

  init() {
    _this.bindActions();
  },
};

const _this = common;

export default _this;
