// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pe2lS9gLmolDPjie5dZ3Tg\\=\\={margin:40px auto 0;max-width:1200px;width:100%}", "",{"version":3,"sources":["webpack://./resources/assets/js/routes/materials/style.module.scss"],"names":[],"mappings":"AAAA,4BAGE,kBAAA,CADA,gBAAA,CADA,UAGF","sourcesContent":[".gridAside {\r\n  width: 100%;\r\n  max-width: 1200px;\r\n  margin: 40px auto 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridAside": "pe2lS9gLmolDPjie5dZ3Tg=="
};
export default ___CSS_LOADER_EXPORT___;
