import React from 'react';
import Card from './card';
import Pagination from '../pagination';
import { BlogContext } from '../../context/BlogContext';

function List() {
  const { dataList } = React.useContext(BlogContext);

  return (
    <>
      {dataList ? (
        <Pagination
          data={dataList}
          RenderComponent={Card}
          pageLimit={6}
          dataLimit={6}
          gridClass="c-grid"
        />
      ) : (
        <p>Nenhum artigo publicado ainda.</p>
      )}
    </>
  );
}

export default List;
