'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const index = {
  init() {
    let $errorMsg = $('.js-participantes-error');

    $('.js-check-volunteers').on('click', function (e) {
      e.preventDefault();

      $errorMsg.hide();

      let employees = $('.employee').filter(':checked').length;
      let externals = $('.external').filter(':checked').length;
      let provider = $('.provider').filter(':checked').length;
      let trainee = $('.trainee').filter(':checked').length;

      $('#js-total-employees').html(employees);
      $('#js-total-external').html(externals);
      $('#js-total-provider').html(provider);
      $('#js-total-trainee').html(trainee);
    });

    $('.js-presence-validate').on('click', function (e) {
      let $errorMsg = $('.js-participantes-error');

      if (
        $('.employee').filter(':checked').length <= 0 &&
        $('.external').filter(':checked').length <= 0 &&
        $('.provider').filter(':checked').length <= 0 &&
        $('.trainee').filter(':checked').length <= 0
      ) {
        e.preventDefault();
        $errorMsg.show();
      } else {
        $errorMsg.hide();
      }
    });

    $('.js-checkall').on('click', function (e) {
      e.preventDefault();
      if (!$("input[name='js-checkall']").prop('checked')) $('input[type=checkbox]').prop('checked', true);
      else $('input[type=checkbox]').prop('checked', false);
    });

    $('.js-form-search').on('keyup', function (e) {
      e.preventDefault();
      w3.filterHTML('.table', '.subscribe-user', $(this).val());
    });

    $('.js-adduser').on('click', function (e) {
      e.preventDefault();
      $('#presence-list').removeClass('active');
      $('#presence-add').addClass('active');
    });

    $('.js-cancell-adduser').on('click', function (e) {
      e.preventDefault();
      $('#presence-list').addClass('active');
      $('#presence-add').removeClass('active');
      $('#guests-table').html('');
    });

    $(document).on('click', '.js-close-panel', function (e) {
      e.preventDefault();
      $('.c-search--panel').addClass('u-display--none');
      $('#new-guest').addClass('u-display--none');
    });

    $(document).on('click', '.js-new-guest', function (e) {
      e.preventDefault();
      $('.js-form-add-users').val();
      $('#js-results').html('');
      $('#new-guest').removeClass('u-display--none');
      $('.c-search--panel-message').addClass('u-display--none');
    });

    $(document).on('keyup', '.js-form-add-users', function (e) {
      e.preventDefault();
      $('.c-search--panel-message').removeClass('u-display--none');

      if ($(this).val().length >= 1) {
        $('.c-search--panel').removeClass('u-display--none');
      } else {
        $('.c-search--panel').addClass('u-display--none');
      }

      if ($(this).val().length >= 3) {
        $('.c-message--label').addClass('u-display--none');

        var post = {};

        post.term = $(this).val();
        post._token = $('meta[name="csrf-token"]').attr('content');
        post.challenge_id = $(this).attr('data-challenge');

        $.ajax({
          type: 'POST',
          url: '/pesquisa-voluntarios/' + post.term + '/challenge',
          data: post,
          dataType: 'json',
          success: function (data) {
            $('#js-results').html(data.html);
          },
        });
      } else {
        $('#new-guest').addClass('u-display--none');
        $('.c-message--label').removeClass('u-display--none');
        $('#js-results').html('');
      }
    });

    $(document).on('change', '.js-volunteer-type', function () {
      if ($("input[name='volunteer_type']:checked").val() == 'employee' || $("input[name='volunteer_type']:checked").val() == 'trainee') {
        $('#enrollment').removeClass('u-display--none');
      } else {
        $('#enrollment').addClass('u-display--none');
      }
    });

    // Abre o formulário de inscrição
    const callForm = (challenge_id, user_id) => {
      $.ajax({
        type: 'POST',
        url: '/formulario-de-acao/',
        data: {
          challenge_id,
          user_id,
          _token: $('meta[name="csrf-token"]').attr('content'),
        },
        dataType: 'json',
        success: function (data) {
          if (data.success) {
            $('body').append(data.html);
          } else {
            Swal.fire(data.title, data.message, data.type);
          }
        },
      });
    };

    // Abre campos dependentes do formulário de inscrição
    $(document).on('change', '.js-select-change', function () {
      $(this).next('[data-type="hidden"]').hide().find('input').val('').attr('required', false);

      let $block = $('#block_' + $(this).val());

      if ($block) {
        if (!$block.is(':visible')) {
          $block.show().find('input').val('').attr('required', true);
        }
      }
    });

    // Submit do formulário de inscrição
    $(document).on('submit', '.js-form-modal', function (e) {
      e.preventDefault();

      $.ajax({
        type: 'POST',
        url: '/formulario-de-acao/enviar',
        data: $(this).serialize(),
        dataType: 'json',
        success: function (data) {
          if (data.success) {
            $('#modal-form').remove();
            subscription(data.challenge_id, data.user_id);
          }
        },
      });
    });

    // Faz a inscrição do usuário selecionado
    const subscription = (challenge_id, user_id) => {
      $.ajax({
        type: 'POST',
        url: '/efetuar-inscricao/challenge',
        data: {
          challenge_id,
          user_id,
          _token: $('meta[name="csrf-token"]').attr('content'),
        },
        dataType: 'json',
        success: function (data) {
          if (data.type === 'success') {
            $('#subscribes-table').append(data.html);
            $('#guests-table').append(data.html);
            $('#counter').html(data.subscribes);

            Swal.fire(data.title, data.message, data.type);

            $('.js-form-add-users').text = '';
            $('.c-search--panel').addClass('u-display--none');
          } else {
            Swal.fire(data.title, data.message, data.type);
          }
        },
      });
    };

    $(document).on('click', '.js-add-presence', function (e) {
      e.preventDefault();

      const challenge_id = $(this).attr('data-challenge');
      const user_id = $(this).attr('data-user');
      const has_form = !!$('[name="has_form"]').length;

      if (has_form) {
        callForm(challenge_id, user_id);
      } else {
        subscription(challenge_id, user_id);
      }
    });

    $(document).on('click', '.js-add-presence-user', function (e) {
      e.preventDefault();

      if ($("input[name='name']").val() == '' || $("input[name='email']").val() == '') {
        Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
        return false;
      }

      var form = $(this).closest('form');
      var post = form.serialize();

      $.ajax({
        type: 'POST',
        url: '/visitante',
        data: post,
        dataType: 'json',
        success: function (data) {
          if (data.type === 'success') {
            $('#subscribes-table').append(data.html);
            $('#guests-table').append(data.html);
            $('#counter').html(data.subscribes);
            form.trigger('reset');
            $('.c-search--panel').addClass('u-display--none');

            Swal.fire(data.title, data.message, data.type);
          } else {
            Swal.fire(data.title, data.message, data.type);
          }
        },
      });

      return false;
    });

    $('.continue').on('click', function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      if (currentSlide == 'volunteers') {
        if ($("input[name='total_employees']").val() == '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide == 'hours') {
        if ($("input[name='total_time_action']").val() == '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide == 'benefiteds') {
        if (
          $("input[name='total_benefited_child']").val() == '' ||
          $("input[name='total_young']").val() == '' ||
          $("input[name='total_adults']").val() == '' ||
          $("input[name='total_seniors']").val() == ''
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });

    $('.back').click(function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      if (nextSlide == 'rede') {
        if ($("input[name='volunteer_type']:checked").val() == 'employee') {
          nextSlide = 'rede';
        } else {
          nextSlide = 'state';
        }
      }

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });
  },
};

export default index;
