// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._77jm0-Qnisv8I0xJGAgGNg\\=\\={width:100%}@media (max-width:1024px){._77jm0-Qnisv8I0xJGAgGNg\\=\\={background-color:#fff;background-color:#610021;bottom:-100%;color:#fff;height:100%;left:0;position:fixed;transition:.4s ease-out;width:100%;z-index:20}}._0-vy8U7DMp3gIBtc-bj5Xg\\=\\={bottom:0}.JPx7Re12baxp5K-bwpsXeg\\=\\={border-radius:8px;padding:15px;position:relative}.p4g8EpKlLxg-q2BqBLSC5g\\=\\={background-color:#fff;display:flex;justify-content:flex-end;padding:15px}@media (min-width:1024px){.p4g8EpKlLxg-q2BqBLSC5g\\=\\={display:none}}.p4g8EpKlLxg-q2BqBLSC5g\\=\\=>span{color:#5848da;text-decoration:underline}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/materials/aside/style.module.scss"],"names":[],"mappings":"AAAA,6BACE,UACF,CACE,0BAHF,6BAII,qBAAA,CAQA,wBAAA,CAFA,YAAA,CAGA,UAAA,CALA,WAAA,CACA,MAAA,CAJA,cAAA,CAMA,uBAAA,CAJA,UAAA,CADA,UASF,CACF,CACA,6BACE,QAEF,CACA,4BACE,iBAAA,CACA,YAAA,CACA,iBAEF,CACA,4BAGE,qBAAA,CAFA,YAAA,CACA,wBAAA,CAEA,YAEF,CAAE,0BANF,4BAOI,YAGF,CACF,CADE,iCACE,aAAA,CACA,yBAGJ","sourcesContent":[".aside {\r\n  width: 100%;\r\n\r\n  @media (max-width: 1024px) {\r\n    background-color: #fff;\r\n    position: fixed;\r\n    z-index: 20;\r\n    width: 100%;\r\n    height: 100%;\r\n    left: 0;\r\n    bottom: -100%;\r\n    transition: 0.4s ease-out;\r\n    background-color: #610021;\r\n    color: #fff;\r\n  }\r\n}\r\n\r\n.active {\r\n  bottom: 0;\r\n}\r\n\r\n.content {\r\n  border-radius: 8px;\r\n  padding: 15px;\r\n  position: relative;\r\n}\r\n\r\n.close {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  background-color: #fff;\r\n  padding: 15px;\r\n\r\n  @media (min-width: 1024px) {\r\n    display: none;\r\n  }\r\n\r\n  > span {\r\n    color: #5848da;\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": "_77jm0-Qnisv8I0xJGAgGNg==",
	"active": "_0-vy8U7DMp3gIBtc-bj5Xg==",
	"content": "JPx7Re12baxp5K-bwpsXeg==",
	"close": "p4g8EpKlLxg-q2BqBLSC5g=="
};
export default ___CSS_LOADER_EXPORT___;
