'use strict';
import React, { useState, useEffect } from 'react';
import Item from './item';

export default function Accordion({ ...props }) {
  const [originalData, setOriginalData] = useState([]);
  const [filtereData, setFilteredData] = useState([]);

  useEffect(() => {
    async function loadData() {
      const res = await fetch(props.uri);
      const data = await res.json();
      setFilteredData(data);
      setOriginalData(data);
    }

    loadData();
  }, []);

  const handleFilter = search => {
    if (search) {
      const filteredData = originalData.filter(item =>
        item.question.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(originalData);
    }
  };

  return (
    <>
      <input
        type="text"
        className="o-input o-input--faq"
        placeholder="O que você está procurando?"
        onChange={e => handleFilter(e.target.value)}
      />

      <dl className="c-faq">
        {filtereData && filtereData.map(item => <Item obj={item} key={item.id} />)}
      </dl>
    </>
  );
}
