'use strict';

import $ from 'jquery';

Dropzone.autoDiscover = false;
import 'dropzone';

const participants = {
  init() {
    const token = $('meta[name="csrf-token"]').attr('content');

    Dropzone.options.myDropzone = {
      url: 'lista-de-presenca/upload-lista',
      paramName: 'presence_list',
      maxFilesize: 10,
      maxFiles: 50,
      params: {
        _token: token,
      },
      addRemoveLinks: true,
      dictDefaultMessage: 'Selecione as imagens que deseja enviar',
      dictCancelUpload: 'Cancelar o envio',
      dictRemoveFile: 'Remover',
      dictMaxFilesExceeded: 'Você já adicionou uma foto',
      dictFileTooBig: 'Arquivo muito grande',
      dictInvalidFileType: 'Este arquivo não é uma imagem',
      init: function () {
        this.on('success', function (file, response) {
          $('.added_presence').append(
            '<input type="hidden" data-name="' + file.name + '" name="presence_list[]" value="' + response + '"/>'
          );
        });
        this.on('removedfile', function (file, response) {
          $("input[name='presence_list[]']").each(function () {
            if ($(this).attr('data-name') == file.name) {
              $(this).remove();
            }
          });
        });
      },
    };

    $('#myDropzone').dropzone();

    Dropzone.options.myDropzoneterms = {
      url: 'lista-de-presenca/upload-termos',
      paramName: 'terms',
      maxFilesize: 10,
      maxFiles: 50,
      params: {
        _token: token,
      },
      addRemoveLinks: true,
      dictDefaultMessage: 'Selecione as imagens que deseja enviar',
      dictCancelUpload: 'Cancelar o envio',
      dictRemoveFile: 'Remover',
      dictMaxFilesExceeded: 'Você já adicionou uma foto',
      dictFileTooBig: 'Arquivo muito grande',
      dictInvalidFileType: 'Este arquivo não é uma imagem',
      init: function () {
        this.on('success', function (file, response) {
          $('.added_terms').append('<input type="hidden" data-name="' + file.name + '" name="terms[]" value="' + response + '"/>');
        });
        this.on('removedfile', function (file, response) {
          $("input[name='terms[]']").each(function () {
            if ($(this).attr('data-name') == file.name) {
              $(this).remove();
            }
          });
        });
      },
    };

    $('#myDropzoneterms').dropzone();

    Dropzone.options.myDropzoneimage = {
      url: 'lista-de-presenca/upload-imagem',
      paramName: 'images',
      maxFilesize: 10,
      maxFiles: 50,
      acceptedFiles:
        'image/*,application/pdf,application/doc,application/docx,application/ppt,application/pptx,application/xls,application/xlsx',
      params: {
        _token: token,
      },
      addRemoveLinks: true,
      dictDefaultMessage: 'Selecione as imagens que deseja enviar',
      dictCancelUpload: 'Cancelar o envio',
      dictRemoveFile: 'Remover',
      dictMaxFilesExceeded: 'Você já adicionou uma foto',
      dictFileTooBig: 'Arquivo muito grande',
      dictInvalidFileType: 'Este arquivo não é uma imagem',
      init: function () {
        this.on('success', function (file, response) {
          $('.added_images').append('<input type="hidden" data-name="' + file.name + '" name="images[]" value="' + response + '"/>');
        });
        this.on('removedfile', function (file, response) {
          $("input[name='images[]']").each(function () {
            if ($(this).attr('data-name') == file.name) {
              $(this).remove();
            }
          });
        });
      },
    };

    $('#myDropzoneimage').dropzone();
  },
};

export default participants;
