import React, { useContext } from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import { MaterialsContext } from '../../../context/MaterialsContext';

export default function Aside({ children }) {
  const { filter, setFilter } = useContext(MaterialsContext);

  return (
    <>
      <aside className={cn(style.aside, { [style.active]: filter })}>
        <div className={style.close}>
          <span onClick={e => setFilter(false)}>Fechar</span>
        </div>
        <div>{children}</div>
      </aside>
    </>
  );
}
