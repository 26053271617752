import React, { useContext } from 'react';
import Slider from 'react-slick';
import style from './style.module.scss';
import { MaterialsContext } from '../../../context/MaterialsContext';
import Card from '../card';
import './slider.scss';
import cn from 'classnames';

function Highlights() {
  const { highlights, showHighlights } = useContext(MaterialsContext);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {highlights.length && showHighlights ? (
        <div className={cn(style.container, 'materials-highlights')}>
          <h2 className={style.categoryTitle}>Destaques</h2>
          <Slider {...settings}>
            {highlights.map(data => {
              return (
                <Card
                  data={data}
                  key={data.id}
                />
              );
            })}
          </Slider>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default Highlights;
