'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const appAccessCode = {
  settings: {
    $btnAccessCode: document.querySelectorAll('.js-access-code'),
    url: '/entrar/codigo-de-acesso',
  },

  bindActions() {
    /* Evento responsável pela requisição de geração de código de acesso por celular */
    let post = {};

    s.$btnAccessCode.forEach($el => {
      $el.addEventListener('click', e => {
        e.preventDefault();

        let v = grecaptcha.getResponse();

        if (v.length == 0) {
          Swal.fire('Você deve marcar o captcha para continuar!', '', 'error');
        } else {
          post.cpf = document.querySelector('input[name="cpf"]').value;
          post._token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

          $.ajax({
            type: 'POST',
            url: s.url,
            data: post,
            dataType: 'json',
            success: function (data) {
              if (data.success) {
                document.getElementById('send-block').classList.remove('u-display--none');

                Swal.fire({
                  title: data.title,
                  text: data.message,
                  icon: 'success',
                });
              } else {
                Swal.fire({
                  title: data.title,
                  text: data.message,
                  icon: 'error',
                });
              }
            },
          });
        }
      });
    });
  },

  init() {
    _this.bindActions();
  },
};

const s = appAccessCode.settings,
  _this = appAccessCode;

export default _this;
