'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';
import { userSubscribe } from '../../modules/userSubscribe';

const show = {
  settings: {
    $lang: document.documentElement.lang,
  },

  init() {
    userSubscribe();

    if ($('.js-share-open').length) {
      document.getElementById('share-btn').click();
    }

    $('.js-additional-check').on('change', function (e) {
      if ($('#' + $(this).attr('data-id')).hasClass('u-display--none')) $('#' + $(this).attr('data-id')).removeClass('u-display--none');
      else {
        $('#' + $(this).attr('data-id')).addClass('u-display--none');
        $('#' + $(this).attr('data-id'))
          .find('input:text')
          .val('');
      }
    });

    $('.js-copy').on('click', function (e) {
      e.preventDefault();

      var range, selection, worked;
      var element = document.getElementById('challenge-link');

      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      try {
        document.execCommand('copy');
        alert('Link copiado');
      } catch (err) {
        alert('unable to copy text');
      }
    });

    let $galleryTitle = {
      'pt-BR': 'Galeria de imagens',
      en: 'Image gallery',
      es: 'Galería de imágenes',
    };

    let $galleryDescription = {
      'pt-BR': 'Não foram adicionadas imagens nessa ação.',
      en: 'No images have been added to this action.',
      es: 'No se añadieron imágenes en esta acción.',
    };

    $('.js-modal-gallery').on('click', function () {
      Swal.fire({
        html: '<h3 class="o-ttl u-m-b-20">' + $galleryTitle[s.$lang] + '</h2><p>' + $galleryDescription[s.$lang] + '</p>',
        padding: '25px 15px',
        position: 'top',
        showConfirmButton: false,
        showClass: {
          popup: 'c-new-swal--popup',
        },
        customClass: 'c-new-swal',
      });
    });

    let $materialsTitle = {
      'pt-BR': 'Materiais adicionais',
      en: 'Additional Materials',
      es: 'Materiales adicionales',
    };

    let $materialsDescription = {
      'pt-BR': 'Não foram adicionados materiais nessa ação.',
      en: 'No materials have been added to this action.',
      es: 'No se añadieron materiales an esta acción.',
    };

    $('.js-modal-materials').on('click', function () {
      Swal.fire({
        html: '<h3 class="o-ttl u-m-b-20">' + $materialsTitle[s.$lang] + '</h2><p>' + $materialsDescription[s.$lang] + '</p>',
        padding: '25px 15px',
        position: 'top',
        showConfirmButton: false,
        showClass: {
          popup: 'c-new-swal--popup',
        },
        customClass: 'c-new-swal',
      });
    });
  },
};

const s = show.settings;

export default show;
