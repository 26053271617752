const select = {
  stateCity() {
    const dfOpt = new Option('Selecione a cidade', '');
    $('.js-state-filter')
      .select2({
        theme: 'classic',
      })
      .on('change', function(e) {
        var post = {};

        post.id = e.target.value;
        post._token = $('meta[name="csrf-token"]').attr('content');

        var url = '/estados/busca-cidades/' + post.id;

        $.ajax({
          type: 'GET',
          url: url,
          data: post,
          cache: false,
          dataType: 'json',
        }).then(function(data) {
          $('#js-cities')
            .empty()
            .append(dfOpt);

          $.each(data, function(index, element) {
            var option = new Option(element, index);
            $('#js-cities').append(option);
          });
        });
      });

    $('#js-cities').select2({
      theme: 'classic',
    });
  },

  area() {
    $('.js-areas').select2({
      placeholder: 'Selecione sua área',
      theme: 'classic',
    });
  },

  unity() {
    $('.js-unity').select2({
      placeholder: 'Selecione sua unidade',
      theme: 'classic',
    });
  },

  funcional() {
    $('.js-funcional').select2({
      placeholder: 'Selecione sua categoria funcional',
      theme: 'classic',
    });
  },
};

export const stateCity = select.stateCity;
export const area = select.area;
export const unity = select.unity;
export const funcional = select.funcional;
