'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const show = {
  init() {
    let $users = $('.js-user'),
      $usersBtn = $('.js-more-user');

    $usersBtn.on('click', function() {
      let cont = 0;
      $users.map(function(index, el) {
        if ($(el).is(':hidden') && cont < 4) {
          $(el).show();
          $('.js-user:hidden').length == 0 ? $usersBtn.hide() : '';
          cont++;
        }
      });
    });

    $('.js-add-committee').on('click', function(e) {
      e.preventDefault();

      var post = {};

      post._token = $('meta[name="csrf-token"]').attr('content');

      var url = '/comites/' + $(this).data('id') + '/integrantes/adicionar/' + $(this).data('participant');

      $.ajax({
        type: 'POST',
        url: url,
        data: post,
        dataType: 'json',
        success: function(data) {
          if (data.type === 'success') {
            location.reload();
          } else {
            Swal.fire(data.title, data.message, data.type);
          }
        },
      });

      return false;
    });
  },
};

const s = show.settings,
  _this = show;

export default show;
