'use strict';

import $ from 'jquery';
import croppieModal from '../../modules/croppieModal';
import { stateCity } from '../../modules/select';
import 'jquery-validation';
import 'jquery-steps/build/jquery.steps.js';

const common = {
  settings: {
    $form: $('.js-committees-form'),
    $steps: $('.js-steps'),
    $mainTtl: $('.js-main-ttl'),
  },

  init() {
    croppieModal.init();

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    });

    s.$form
      .on('submit', function(e) {
        e.preventDefault();
      })
      .validate({
        rules: {
          name: {
            required: true,
          },
          description: {
            required: true,
          },
        },
        messages: {
          name: {
            required: 'Campo obrigatório',
          },
          description: {
            required: 'Campo obrigatório',
          },
        },
        submitHandler: function(form) {
          form.submit();
        },
      });

    s.$steps.steps({
      headerTag: 'h1',
      bodyTag: 'div',
      transitionEffect: 'fade',
      cssClass: 'c-form-steps',
      enablePagination: false,
      onInit: function(event, currentIndex) {
        s.$mainTtl.html(
          $(event.target)
            .find('.content .title.current')
            .text()
        );
      },
      onStepChanging: function(event, currentIndex, newIndex) {
        if (currentIndex < newIndex) {
          s.$form.validate().settings.ignore = ':disabled,:hidden';

          let arrErrors = [];

          $(event.target)
            .find('.body.current select[required]')
            .each(function(index, el) {
              if ($(el).val() === '') {
                arrErrors.push(el);
              }
            });

          if (arrErrors.length) {
            $.each(arrErrors, function(index, el) {
              $(el).addClass('error');
            });
            return false;
          } else {
            return s.$form.valid();
          }
        } else {
          return true;
        }
      },
      onFinishing: function(event, currentIndex) {
        s.$form.validate().settings.ignore = ':disabled';
        let arrErrors = [];

        $(event.target)
          .find('.body.current select[required]')
          .each(function(index, el) {
            if ($(el).val() === '') {
              arrErrors.push(el);
            }
          });

        if (arrErrors.length) {
          $.each(arrErrors, function(index, el) {
            $(el).addClass('error');
          });
          return false;
        } else {
          return s.$form.valid();
        }
      },
      onFinished: function(event, currentIndex) {
        s.$form.trigger('submit');
      },
      onStepChanged: function(event, currentIndex, newIndex) {
        s.$mainTtl.html(
          $(event.target)
            .find('.content .title.current')
            .text()
        );
      },
    });

    $('select[required]').on('change', function() {
      if ($(this).val() !== '') {
        $(this).removeClass('error');
      }
    });

    $('.js-steps-prev').on('click', function() {
      s.$steps.steps('previous');
    });

    $('.js-steps-next').on('click', function() {
      s.$steps.steps('next');
    });

    $('.js-steps-submit').on('click', function() {
      s.$steps.steps('finish');
    });

    stateCity();
  },
};

const s = common.settings,
  _this = common;

export default common;
