import React, { useState, useEffect, useContext } from 'react';
import Button from '../../button';
import style from './style.module.scss';
import cn from 'classnames';
import { MaterialsContext } from '../../../context/MaterialsContext';

export default function Card({ data: { id, title, subcategory, description, external_url, url, cover } }) {
  const [animate, setAnimate] = useState(true);
  const [textSize] = useState(90);
  const { setModalContent } = React.useContext(MaterialsContext);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 100);
    return () => {
      setAnimate(true);
    };
  }, [id]);

  function resolveUrl(url) {
    if (!url.includes('http://') && !url.includes('https://')) {
      url = 'http://' + url;
    }
    return url;
  }

  function handleModalOpen() {
    setModalContent(ModalContent(subcategory, title, description));
  }

  return (
    <>
      <div className={cn(style.card, { [style.animate]: animate })} key={id}>
        <img src={cover} alt={title} className={style.cover} />
        <div className={style.wrapper}>
          <h3 className={style.subcategoryTitle}>{subcategory}</h3>
          <h2 className={style.title}>{title}</h2>

          <p>
            {description.substring(0, textSize).trim()}
            {description.length >= textSize && '...'}
            {description.length >= textSize && (
              <span className={style.more} onClick={e => handleModalOpen()}>
                ver mais
              </span>
            )}
          </p>

          <div className="c-card-material__action">
            <Button href={external_url ? resolveUrl(external_url) : url} type="primary" size="full" target="_blank">
              {external_url ? (
                <>
                  <span className="fa fa-external-link-alt"></span> Acessar
                </>
              ) : (
                <>
                  <span className="fa fa-download"></span> Baixar material
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export function ModalContent(subcategory, title, description) {
  return (
    <>
      <h3 className={style.modalSubcategoryTitle}>{subcategory}</h3>
      <h2 className={style.modalTitle}>{title}</h2>
      <p>{description.trim()}</p>
    </>
  );
}
