'use strict';
import React from 'react';
import Accordion from '../../react/components/accordion';

export default function Faq() {
  let lang = document.documentElement.lang;
  const apiUri = `${window.location.origin}/api/v1/${lang}/perguntas-frequentes`;
  return (
    <>
      <section className="o-section">
        <div className="o-wrapper">
          <Accordion uri={apiUri} />
        </div>
      </section>
    </>
  );
}
