'use strict';

import $ from 'jquery';
Dropzone.autoDiscover = false;
import 'dropzone';
import Swal from 'sweetalert2';
import dateRange from './dateRange';
import geoLocation from './geoLocation';
import mask from 'jquery-mask-plugin';

const challengesForm = {
  settings: {
    _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  },

  bindActions() {
    $('.continue').on('click', function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      if (currentSlide == 'first') {
        if (
          !$('input[name="activities[]"]').is(':checked') ||
          $("input[name='title']").val() == '' ||
          $("input[name='is_limited']:checked").val() == null ||
          ($("input[name='is_limited']:checked").val() == 1 && $("input[name='vacancies']").val() == '') ||
          ($('.js-others').is(':checked') && $('.js-type-description').val().length == 0) ||
          !tinymce.get('about-description').getContent().length
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide == 'organization') {
        let $type = $('#c-type input[name="type"]:checked');

        if (
          $type.length === 0 ||
          $('.js-organization option:selected').val() == '' ||
          ($('.js-organization option:selected').val() == '1' && $("input[name='school_name']").val() == '') ||
          ($('.js-organization option:selected').val() == '1' && $("input[name='cnpj']").val() == '') ||
          ($('.js-organization option:selected').val() == '2' && $("input[name='organization_name']").val() == '') ||
          ($('.js-organization option:selected').val() == '2' && $("input[name='cnpj']").val() == '') ||
          ($('.js-organization option:selected').val() == '2' && $("input[name='social_object']").val() == '') ||
          ($('.js-organization option:selected').val() == '3' && $("input[name='public_name']").val() == '') ||
          ($('.js-organization option:selected').val() == '5' && $("input[name='multi_organization_name[1]']").val() == '') ||
          ($('.js-organization option:selected').val() == '5' && $("input[name='multi_organization_cnpj[1]']").val() == '') ||
          ($('.js-organization option:selected').val() == '6' && $("input[name='cnpj']").val() == '')
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        } else if (
          $type.val() === 'present' &&
          (!$('.js-state-filter').val() || !$('.js-cities').val() || !$('[name="school_address"]').val().length)
        ) {
          Swal.fire('Atenção!', 'Para ações presenciais os campos de endereço/estado/cidade são obrigatórios.', 'error');
          return false;
        }
      }

      if (currentSlide == 'due_date') {
        if ($("input[name='calendar']").val() == '' || $('#time_start_at').val() == '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide == 'materials') {
        if ($('[name="material_name[]"]').length) {
          for (let $i = 0; $i < $('[name="material_name[]"]').length; $i++) {
            let $el = $('[name="material_name[]"]')[$i];
            if (!$el.value.length) {
              Swal.fire('Atenção!', 'Preencha o nome dos materiais', 'error');
              return false;
            }
          }
        }
      }

      if (currentSlide == 'plural') {
        if (
          $('.js-ods').val() == '' ||
          $('.js-plural').val() == '' ||
          ($('[name="public_description"]').is(':visible') && $('[name="public_description"]').val() == '')
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      $('.publish-challenge').on('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        if ($('#cover_url').val() == '' && $('#customFile').val() == '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }

        $('.form-signin').submit();
      });

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });

    $('.back').click(function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });

    $('.js-limited').on('change', function () {
      if ($("input[name='is_limited']:checked").val() == '1') {
        $('#js-vacancies--total').removeClass('u-display--none');
      } else {
        $('#js-vacancies--total').addClass('u-display--none');
      }
    });

    function hideAll() {
      $('#c-school').addClass('u-display--none');
      $('#c-school input[name = "school_name"]').val('');
      $('#c-school input[name = "inep"]').val('');
      $('#c-school input[name = "register_number"]').val('');
      $('#add-cnpj').addClass('u-display--none');
      $('#add-cnpj input[name = "cnpj"]').val('');

      $('#c-organization').addClass('u-display--none');
      $('#c-organization input[name = "organization_name"]').val('');

      $('#c-public').addClass('u-display--none');
      $('#c-public input[name = "public_name"]').val('');

      $('#c-organizationlist').addClass('u-display--none');
      $('.js-organizationlist').val('');

      $('input[name = "school_address"]').val('');
      $('input[name = "multi_organization_name[1]"]').val('');
      $('input[name = "multi_organization_cnpj[1]"]').val('');
      $('input[name = "multi_organization_social_object[1]"]').val('');

      $('#add-social-object').addClass('u-display--none');
      $('#add-social-object input[name="social-object"]').val('');

      $('#contact-organization').addClass('u-display--none');
      $('#contact-organization input').val('');

      let num = $('.js-add-organization').attr('data-count');
      if (num > 1) {
        for (i = 2; i <= num; i++) {
          $('#organization-id-' + i).remove();
        }
      }
    }

    $("#c-type input[name='type']").on('change', () => {
      let type = $("input[name='type']:checked").val();
      $('#s-organization').removeClass('u-display--none');
      type == 'online' ? $('#addess-label').text('Endereço do local da ação') : $('#addess-label').text('Endereço do local da ação*');
    });

    $('.js-organization').on('change', function () {
      let type = $("input[name='type']:checked").val();

      if (this.value == 1) {
        hideAll();
        $('#c-school').removeClass('u-display--none');
        $('#add-cnpj').removeClass('u-display--none');
        $('#contact-organization').removeClass('u-display--none');
        $('#label-cnpj').html('CNPJ / INEP / Nº de registro *');
        $('#input-cnpj').attr('placeholder', 'CNPJ / INEP / Nº de registro *');
      } else if (this.value == 2) {
        hideAll();
        $('#c-organization').removeClass('u-display--none');
        $('#add-cnpj').removeClass('u-display--none');
        $('#contact-organization').removeClass('u-display--none');
        $('#add-social-object').removeClass('u-display--none');
        $('#label-cnpj').html('CNPJ*');
        $('#input-cnpj').attr('placeholder', 'CNPJ*');
      } else if (this.value == 3) {
        hideAll();
        $('#c-public').removeClass('u-display--none');
        $('#contact-organization').removeClass('u-display--none');
      } else if (this.value == 4) {
        hideAll();
      } else if (this.value == 5) {
        hideAll();
        $('#c-organizationlist').removeClass('u-display--none');
      } else if (this.value == 6) {
        hideAll();
        $('#add-cnpj').removeClass('u-display--none');
        $('#label-cnpj').html('CNPJ*');
      } else {
        hideAll();
      }
    });

    $(document).on('click', '.js-add-organization', function (e) {
      e.preventDefault();

      let num = parseInt($(this).attr('data-count')) + 1;

      let template = `
          <div class="o-card u-mt-30" id="organization-id-${num}">
            <label class="u-mt-15">Nome da organização*</label>
            <input type="text" class="form-control" name="multi_organization_name[${num}]" placeholder="Nome da organização" maxlength="80">
            
            <label class="u-mt-15">CNPJ*</label>
            <input type="text" class="form-control js-form-cnpj" name="multi_organization_cnpj[${num}]" placeholder="CNPJ da organização" maxlength="20" >

            <div class="o-card--button">
              <button class="o-btn o-btn--danger js-drop-organization u-mt-15" data-id="organization-id-${num}"><i class="fa fa-trash"></i> Remover</button>
            </div>
          </div>`;

      $('.s-challenges--create-grid').append(template);
      $('.js-form-cnpj').mask('99.999.999/9999-99');

      $(this).attr('data-count', num);
    });

    $(document).on('click', '.js-drop-organization', function (e) {
      e.preventDefault();
      $('#' + $(this).attr('data-id')).remove();
    });

    $('.js-thumb').on('click', function () {
      document.getElementById('cover_chosen').src = $(this).attr('src');
      document.getElementById('cover_url').value = $(this).attr('src');
    });

    var reader = new FileReader();
    var coverChosen = document.getElementById('cover_chosen');
    var coverUrl = document.getElementById('cover_url');

    $('.js-challenge-cover').on('change', function (e) {
      let cover = reader.readAsDataURL(this.files[0]);

      reader.onloadend = function () {
        coverChosen.src = reader.result;
        coverUrl.value = '';
      };
    });

    var $typeDescruption = $('#type-description');
    $('.js-others').on('change', function () {
      if ($(this).is(':checked')) {
        $typeDescruption.slideDown();
      } else {
        $typeDescruption.slideUp();
        $('.js-type-description').val('');
      }
    });

    $('.js-activity').on('click', function () {
      var $box = $(this);
      if ($box.is(':checked')) {
        var group = "input:checkbox[name='" + $box.attr('name') + "']";
        $(group).prop('checked', false);
        $box.prop('checked', true);
        if ($box.attr('value') != 'Outros') {
          $('.js-type-description').val('');
          $typeDescruption.slideUp();
        }
      } else {
        $box.prop('checked', false);
      }
    });

    // Select2 ODS controller
    function formatOds(ods) {
      if (ods.icon) {
        var baseUrl = '/images/icons/ods';
        var $ods = $(
          '<span class="c-drop-template__title"><img src="' +
            baseUrl +
            '/' +
            ods.icon +
            '" class="c-drop-template__flag" /> ' +
            ods.text +
            '</span>'
        );
        return $ods;
      }
    }

    fetch(`${window.location.origin}/get/ods`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('input[name="_token"]').value,
      },
      body: JSON.stringify({
        challenge_id: document.querySelector('input[name="id"]') !== null ? document.querySelector('input[name="id"]').value : 0,
      }),
    })
      .then(response => (response.ok ? response.json() : console.log('Network response was not ok.')))
      .then(data => {
        $('#js-ods')
          .select2({
            placeholder: 'Selecione',
            theme: 'classic',
            multiple: true,
            data: JSON.parse(data.ods),
            templateSelection: formatOds,
            templateResult: formatOds,
          })
          .val(data.odsSelected)
          .trigger('change');
      })
      .catch(error => {
        console.log('There has been a problem with your fetch operation: ' + error.message);
      });

    $('.js-plural')
      .select2({
        placeholder: 'Selecione',
        theme: 'classic',
        multiple: true,
      })
      .on('select2:select', function (e) {
        if (e.params.data.text == 'Outros') {
          $('#public-description').slideDown();
        }
      })
      .on('select2:unselect', function (e) {
        if (e.params.data.text == 'Outros') {
          $('#public-description').slideUp();
          $('.js-public-description').val('');
        }
      });
  },

  dropzone() {
    new Dropzone('#myDropzone', {
      url: `${window.location.origin}/acoes-disponiveis/materiais/upload-arquivos`,
      paramName: 'gallery',
      maxFilesize: 10,
      maxFiles: 50,
      params: {
        _token: s._token,
        challenge_id: $('[name="id"]').val(),
      },
      previewTemplate: document.querySelector('#tpl').innerHTML,
      addRemoveLinks: true,
      dictDefaultMessage: 'Selecione os arquivos que deseja enviar',
      dictCancelUpload: 'Cancelar o envio',
      dictRemoveFile: 'Remover',
      dictMaxFilesExceeded: 'Você já adicionou um arquivo',
      dictFileTooBig: 'Arquivo muito grande',
      dictInvalidFileType: 'Este arquivo não é uma imagem',
      init: function () {
        this.on('success', function (file, response) {
          $('.added_photos').append('<input type="hidden" data-name="' + file.name + '" name="gallery[]" value="' + response + '"/>');

          let $item = $('<input type="hidden" data-name="' + file.name + '" name="material_name[]"/>');
          $('.added_photos').append($item);

          $(file.previewElement.querySelector('[data-item-name]')).on('keyup', function (e) {
            $item[0].value = e.target.value;
          });
        });
        this.on('removedfile', function (file, response) {
          $("input[name='gallery[]'], input[name='material_name[]']").each(function () {
            if ($(this).attr('data-name') == file.name) {
              $(this).remove();
            }
          });
        });
      },
    });
  },

  init() {
    _this.bindActions();
    _this.dropzone();
    dateRange.init();
    geoLocation.init();
  },
};

const s = challengesForm.settings,
  _this = challengesForm;

export default _this;
