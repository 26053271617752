import React from 'react';
import Show from '../../components/blog/show';
import List from '../../components/blog/list';
import { BlogContext } from '../../context/BlogContext';

function Router() {
  const { urlVerify, newsActive } = React.useContext(BlogContext);

  React.useEffect(() => {
    urlVerify();
  }, []);

  return <>{!newsActive ? <List /> : <Show />}</>;
}

export default Router;
