// Style
import style from './style.module.scss';

// External
import cn from 'classnames';

// O componente de botão contém algumas pré-configurações de layout sendo elas:
// type: primary, secondary, tertiary...
// size: small, medium, large, full
function Button({ href = false, type = 'primary', size = 'medium', submit = false, className, children, ...props }) {
  return !href ? (
    <button
      className={cn(style.button, style[type], style[size], className)}
      type={submit ? 'submit' : ''}
      {...props}
    >
      {children}
    </button>
  ) : (
    <a
      href={href}
      className={cn(style.button, style[type], style[size], className)}
      {...props}
    >
      {children}
    </a>
  );
}

export default Button;
