import React from 'react';

import style from './style.module.scss';
import cn from 'classnames';

function Subtitle({ size = 16, weight = 'normal', children }) {
  return (
    <>
      <p
        className={cn(style.subtitle)}
        style={{ fontSize: size, fontWeight: weight }}
      >
        {children}
      </p>
    </>
  );
}

export default Subtitle;
