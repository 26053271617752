import React, { useState, createContext, useEffect } from 'react';

export const MaterialsContext = createContext();

export const MaterialsStorage = ({ children }) => {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [categories, setCategories] = useState({});
  const [modalContent, setModalContent] = useState(null);
  const [showHighlights, setShowHighlights] = useState(true);
  const [filter, setFilter] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch('/api/v1/materiais/destaques');
        const data = await res.json();
        setHighlights(data);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch(`/api/v1/materiais/?&page=${currentPage}`);
        const data = await res.json();
        setOriginalData(data.data);
        setFilteredData(data.data);
        data.last_page == 1 && setLoadMore(false);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
  }, [currentPage]);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch(`/api/v1/materiais/categorias`);
        const data = await res.json();
        setCategories(data);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
  }, []);

  return (
    <MaterialsContext.Provider
      value={{
        originalData,
        filteredData,
        setFilteredData,
        categories,
        modalContent,
        setModalContent,
        highlights,
        showHighlights,
        setShowHighlights,
        filter,
        setFilter,
        currentPage,
        setCurrentPage,
        loadMore,
        setLoadMore,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};
