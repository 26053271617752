import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';

function GridAside({ className, children }) {
  return (
    <div
      className={cn(style.grid, className)}
      style={style}
    >
      {children}
    </div>
  );
}

export default GridAside;
