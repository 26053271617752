// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j4guVi9RkRrtz1dywLHzAg\\=\\={background-color:rgba(0,0,0,.5);bottom:0;left:0;position:fixed;right:0;top:0;z-index:20}.Avukbgeq2jW-EOhr0wq1qg\\=\\={background-color:#fff;border-radius:5px;left:50%;max-height:80vh;max-width:80vw;overflow-y:auto;padding:25px 40px;position:fixed;top:50%;transform:translate(-50%,-50%);width:80vw;z-index:21}@media (min-width:1024px){.Avukbgeq2jW-EOhr0wq1qg\\=\\={max-height:330px;max-width:520px}}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/modal/style.module.scss"],"names":[],"mappings":"AAAA,4BAME,+BAAA,CADA,QAAA,CAFA,MAAA,CAFA,cAAA,CAGA,OAAA,CAFA,KAAA,CAKA,UACF,CAEA,4BACE,qBAAA,CASA,iBAAA,CANA,QAAA,CAIA,eAAA,CADA,cAAA,CAKA,eAAA,CAHA,iBAAA,CAPA,cAAA,CACA,OAAA,CAEA,8BAAA,CACA,UAAA,CAKA,UAEF,CACE,0BAdF,4BAgBI,gBAAA,CADA,eAGF,CACF","sourcesContent":[".darkbox {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: rgba(#000, 0.5);\r\n  z-index: 20;\r\n}\r\n\r\n.modal {\r\n  background-color: #fff;\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 80vw;\r\n  max-width: 80vw;\r\n  max-height: 80vh;\r\n  padding: 25px 40px;\r\n  border-radius: 5px;\r\n  z-index: 21;\r\n  overflow-y: auto;\r\n\r\n  @media (min-width: 1024px) {\r\n    max-width: 520px;\r\n    max-height: 330px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkbox": "j4guVi9RkRrtz1dywLHzAg==",
	"modal": "Avukbgeq2jW-EOhr0wq1qg=="
};
export default ___CSS_LOADER_EXPORT___;
