'use strict';

import $ from 'jquery';

import dateRange from '../../modules/dateRange';
import { stateCity } from '../../modules/select';
import { userSubscribe } from '../../modules/userSubscribe';

const index = {
  init() {
    dateRange.init();
    stateCity();
    userSubscribe();
  },
};

const s = index.settings,
  _this = index;

export default _this;
