'use strict';

import $ from 'jquery';

const password = {
  init() {
    function complexPassword() {
      var password = document.getElementById('password_user');
      var regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){6})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;

      if (password.length < 8) {
        password.focus();
        return false;
      } else if (!regex.exec(password.value)) {
        password.focus();
        return false;
      }
      return true;
    }

    function Validate() {
      var password = document.getElementById('password_user').value;
      var confirmPassword = document.getElementById('password_confirmation').value;

      if (password != confirmPassword) {
        return false;
      }

      if (!complexPassword()) {
        return false;
      }

      $('#btn-change-password').prop('disabled', false);
      return true;
    }

    $('#password_confirmation').on('keyup', function() {
      if (!Validate()) {
        $('#js-diff-passwords').removeClass('u-display--none');
      } else {
        $('#js-diff-passwords').addClass('u-display--none');
      }
    });
  },
};

export default password;
