'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const savedreport = {
  init() {
    $('.js-success').on('click', function () {
      let $lang = document.documentElement.lang;

      let $title = {
        'pt-BR': 'Obrigado!',
        'en': 'Thank you!',
        'es': '¡Gracias!',
      };

      let $text = {
        'pt-BR': 'Ação concluída!',
        'en': 'Action completed!',
        'es': '¡Acción completada!',
      };

      Swal.fire({
        title: $title[$lang],
        text: $text[$lang],
        icon: 'success',
      }).then(() => {
        window.location.href = '/minhas-acoes';
      });
    });
  },
};

export default savedreport;
