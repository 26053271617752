import React from 'react';
import { BlogContext } from '../../context/BlogContext';

function Show() {
  const { dataNews, setNewsActive } = React.useContext(BlogContext);

  function handleBack(e) {
    e.preventDefault();
    setNewsActive(null);
  }

  return (
    <>
      <div className="u-m-b-30">
        <a
          href="#"
          onClick={e => handleBack(e)}
          className="o-ttl o-ttl--color-brand"
        >
          <span className="fa fa-chevron-left"></span> Notícias
        </a>
      </div>
      {!dataNews.length ? (
        <>
          <p className="s-blog--item-date">{dataNews.published_at}</p>
          <h2 className="s-blog--item-title">{dataNews.title}</h2>

          <div
            className="c-post"
            dangerouslySetInnerHTML={{
              __html: dataNews.description,
            }}
          />
        </>
      ) : (
        <p>
          Esta página não existe!
          <br /> Clique{' '}
          <a
            href=""
            onClick={e => handleBack(e)}
          >
            aqui
          </a>{' '}
          para retornar à listagem de notícias.
        </p>
      )}
    </>
  );
}

export default Show;
