'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const index = {
  init() {
    $('#form-recovery-email').on('submit', function(e) {
      e.preventDefault();

      var post = {};

      post.cpf = $('#cpf').val();
      post._token = $('meta[name="csrf-token"]').attr('content');

      var url = $(this).attr('action');

      $.ajax({
        type: 'POST',
        url: url,
        data: post,
        dataType: 'json',
        success: function(data) {
          Swal.fire(data.title, data.message, data.type);
        },
      });
    });
  },
};

export default index;
