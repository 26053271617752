// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yFbC8d115567J\\+vpqyWrew\\=\\={margin:0 auto;max-width:1200px;padding:0 15px 50px;width:100%}@media (min-width:1024px){.yFbC8d115567J\\+vpqyWrew\\=\\={padding:0 15px}}.bMj3wzLOzf8ztDUL76Odlw\\=\\={color:#333;font-size:20px;font-weight:700;margin-bottom:25px;margin-top:45px}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/materials/highlights/style.module.scss"],"names":[],"mappings":"AAAA,6BAGE,aAAA,CADA,gBAAA,CAEA,mBAAA,CAHA,UAIF,CACE,0BANF,6BAOI,cAEF,CACF,CACA,4BAGE,UAAA,CADA,cAAA,CADA,eAAA,CAGA,kBAAA,CACA,eAEF","sourcesContent":[".container {\r\n  width: 100%;\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n  padding: 0 15px 50px;\r\n\r\n  @media (min-width: 1024px) {\r\n    padding: 0 15px 0;\r\n  }\r\n}\r\n\r\n.categoryTitle {\r\n  font-weight: 700;\r\n  font-size: 20px;\r\n  color: #333333;\r\n  margin-bottom: 25px;\r\n  margin-top: 45px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "yFbC8d115567J+vpqyWrew==",
	"categoryTitle": "bMj3wzLOzf8ztDUL76Odlw=="
};
export default ___CSS_LOADER_EXPORT___;
