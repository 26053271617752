import $ from 'jquery';
import Swal from 'sweetalert2';

export const userSubscribe = () => {
  // variáveis globais
  const $btnParticipating = $('[data-type="participating"]'),
    $btnDisparticipate = $('[data-type="disparticipate"]'),
    $btnParticipate = $('[data-type="participate"]');

  // Faz a inscrição
  function subscription(challengeId) {
    let isInternal = $('body').attr('data-controller') == 'challenges' && $('body').attr('data-action') == 'show' ? true : false;

    $.ajax({
      type: 'POST',
      url: '/efetuar-inscricao/',
      data: {
        challenge_id: challengeId,
        _token: $('meta[name="csrf-token"]').attr('content'),
      },
      dataType: 'json',
      success: function (data) {
        if (data.type === 'success') {
          Swal.fire(data.title, data.message, data.type);

          if (isInternal) {
            $btnParticipate.hide();
            $btnDisparticipate.show();
            $btnParticipating.show();
          } else {
            let $cards = $('.c-card[data-id="' + challengeId + '"]');

            $cards
              .find('[data-type="participate"]')
              .html('deixar de participar')
              .addClass('o-btn--subscribed')
              .removeClass('o-btn--primary')
              .attr('data-type', 'disparticipate');

            $cards.find('.c-card__status').addClass('active').text('você está participando');
          }
        } else {
          Swal.fire(data.title, data.message, data.type);
        }
      },
    });
  }

  // Chama o formulário de inscrição
  function challengeForm(challengeId) {
    $.ajax({
      type: 'POST',
      url: '/formulario-de-acao/',
      data: {
        challenge_id: challengeId,
        _token: $('meta[name="csrf-token"]').attr('content'),
      },
      dataType: 'json',
      success: function (data) {
        if (data.success) {
          $('body').append(data.html);
        }
      },
    });
  }

  // Abre campos dependentes
  $(document).on('change', '.js-select-change', function () {
    $(this).next('[data-type="hidden"]').hide().find('input').val('').attr('required', false);

    let $block = $('#block_' + $(this).val());

    if ($block) {
      if (!$block.is(':visible')) {
        $block.show().find('input').val('').attr('required', true);
      }
    }
  });

  // Submit do formulário
  $(document).on('submit', '.js-form-modal', function (e) {
    e.preventDefault();

    $.ajax({
      type: 'POST',
      url: '/formulario-de-acao/enviar',
      data: $(this).serialize(),
      dataType: 'json',
      success: function (data) {
        if (data.success) {
          $('#modal-form').remove();
          subscription(data.challenge_id);
        }
      },
    });
  });

  // Evento do botão de participar
  $(document).on('click', '[data-type="participate"]', function (e) {
    e.preventDefault();
    let challengeId = $(this).attr('data-challenge'),
      isForm = $(this).is('[data-form]');

    if (isForm) {
      challengeForm(challengeId);
    } else {
      subscription(challengeId);
    }
  });

  // Evento do botão de desparticipar
  $(document).on('click', '[data-type="disparticipate"]', function (e) {
    e.preventDefault();

    Swal.fire({
      title: 'Você tem certeza que quer deixar de participar desta ação?',
      showCancelButton: true,
      confirmButtonColor: '#d7171e',
      cancelButtonColor: '#fff',
      cancelButtonText: 'NÃO TENHO',
      confirmButtonText: 'SIM, TENHO',
      padding: '50px',
      reverseButtons: true,
      customClass: 'c-new-swal',
    }).then(result => {
      if (result.value) {
        let challengeId = $(this).attr('data-challenge'),
          isInternal = false;

        if ($('body').attr('data-controller') == 'challenges' && $('body').attr('data-action') == 'show') {
          isInternal = true;
        }

        $.ajax({
          type: 'POST',
          url: '/cancelar-inscricao/',
          data: {
            challenge_id: challengeId,
            _token: $('meta[name="csrf-token"]').attr('content'),
          },
          dataType: 'json',
          success: function (data) {
            if (data.type === 'success') {
              window.location.reload();
            } else {
              Swal.fire(data.title, data.message, data.type);
            }
          },
        });
      }
    });
  });
};
