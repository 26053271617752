'use strict';

import $ from 'jquery';

const index = {
  init() {
    $('.js-see--more').on('click', function(e) {
      e.preventDefault();
      let $more = $(this);
      $more
        .parent()
        .siblings('.js-card-list')
        .children()
        .map(function(i, el) {
          $(el).is(':hidden') ? $(el).show() | $more.hide() : '';
        });
    });
  },
};

export default index;
