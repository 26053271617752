import React, { useContext, useState, useEffect } from 'react';
import { MaterialsContext } from '../../../context/MaterialsContext';
import style from './style.module.scss';
import cn from 'classnames';

function Categories() {
  const { originalData, setFilteredData, categories, setShowHighlights, setLoadMore, setCurrentPage } = useContext(MaterialsContext);
  const [categoryActive, setCategoryActive] = useState(null);
  const [subCategoryActive, setSubCategoryActive] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch(`/api/v1/materiais/?filter[category]=${categoryActive}`);
        const data = await res.json();
        setFilteredData(data.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (categoryActive) {
      loadData();
      setShowHighlights(false);
      setLoadMore(false);
    }
  }, [categoryActive]);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch(`/api/v1/materiais/?filter[subcategory]=${subCategoryActive}`);
        const data = await res.json();
        setFilteredData(data.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (subCategoryActive) {
      loadData();
      setShowHighlights(false);
      setLoadMore(false);
    }
  }, [subCategoryActive]);

  function handleSearch() {
    document.getElementById('search').focus();
  }

  function filterHighlighst() {
    setShowHighlights(true);
    setFilteredData(false);
    setLoadMore(false);
    setCategoryActive(null);
    setSubCategoryActive(null);
  }

  function filterAll() {
    setShowHighlights(true);
    setFilteredData(originalData);
    setLoadMore(true);
    setCategoryActive(null);
    setSubCategoryActive(null);
    setCurrentPage(1);
  }

  return (
    <div className={style.wrapper}>
      <button
        onClick={e => filterAll()}
        className={cn(style.button, style.all)}
      >
        Todos materiais
      </button>

      <button
        className={cn(style.button, style.search)}
        onClick={e => handleSearch()}
      >
        Busca
      </button>

      <button
        onClick={e => filterHighlighst()}
        className={cn(style.button)}
      >
        Destaques
      </button>

      {categories.length &&
        categories.map(category => {
          return (
            <div
              className={style.filter}
              key={category.title + '_cat'}
            >
              <a
                href="#"
                className={style.item}
                onClick={e => setCategoryActive(category.title)}
              >
                {category.title}
              </a>
              <ul>
                {category.subcategories.length &&
                  category.subcategories.map(subcategory => {
                    return (
                      <li key={subcategory + '_sub'}>
                        <a
                          href="#"
                          className={style.itemChild}
                          onClick={e => setSubCategoryActive(subcategory)}
                        >
                          {subcategory}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
        })}
    </div>
  );
}

export default Categories;
