import React, { useEffect, useState } from 'react';

export default function Pagination({
  data,
  RenderComponent,
  pageLimit,
  dataLimit,
  gridClass,
  ...props
}) {
  const [pages, setPages] = useState(Math.ceil(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setPages(Math.ceil(data.length / dataLimit));
  }, [data]);

  function goToNextPage() {
    if (currentPage < pages) {
      setCurrentPage(page => page + 1);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(page => page - 1);
    }
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div>
      <div className={gridClass}>
        {getPaginatedData().map((d, idx) => (
          <RenderComponent key={idx} data={d} {...props} />
        ))}
      </div>

      {pages > 1 ? (
        <section className="c-pagination">
          <nav className="c-pagination__nav">
            {getPaginationGroup().map((item, index) =>
              item <= pages ? (
                <button
                  key={index}
                  onClick={changePage}
                  className={`c-pagination__nav-item ${
                    currentPage === item ? 'c-pagination__nav-item--active' : ''
                  }`}
                >
                  <span>{item}</span>
                </button>
              ) : null
            )}
          </nav>
          <div className="c-pagination__actions">
            <button
              onClick={goToPreviousPage}
              className={`c-pagination__btn ${
                currentPage === 1 ? 'c-pagination__btn--disabled' : ''
              }`}
            >
              <span>Anterior</span>
            </button>
            <button
              onClick={goToNextPage}
              className={`c-pagination__btn ${
                currentPage === pages ? 'c-pagination__btn--disabled' : ''
              }`}
            >
              Próximo
            </button>
          </div>
        </section>
      ) : (
        ''
      )}
    </div>
  );
}
