'use strict';

import $ from 'jquery';

const materials = {
  init() {
    const $search = document.getElementById('js-search'),
      $listContainer = document.getElementById('js-list-container'),
      $listContainerDump = $listContainer.querySelector('.c-materials-list'),
      $pagination = document.getElementById('js-pagination'),
      $filterType = document.getElementById('js-filter-type');

    $search.addEventListener('keyup', e => {
      filterRequest(e.target.value, $filterType.value.length > 0 ? $filterType.value : null);
    });

    $filterType.addEventListener('change', e => {
      filterRequest($search.value.length > 0 ? $search.value : null, e.target.value);
    });

    let delay;
    function filterRequest(therm = null, type = null) {
      clearTimeout(delay);
      delay = setTimeout(() => {
        if (therm || type) {
          $.ajax({
            url: '/acoes/materiais/filtro',
            type: 'POST',
            data: {
              _token: document.querySelector('[name="csrf-token"]').getAttribute('content'),
              _id: document.querySelector('[name="challenge_id"]').value,
              _therm: therm,
              _type: type,
            },
            success: function(data) {
              if (data.success) {
                $listContainer.innerHTML = '';
                $($listContainer).append($(data.materials));

                if ($pagination) {
                  $pagination.classList.add('is-hidden');
                }
              } else {
                console.log('Erro!');
              }
            },
          });
        } else {
          $listContainer.innerHTML = '';
          $listContainer.append($listContainerDump);

          if ($pagination) {
            $pagination.classList.remove('is-hidden');
          }
        }
      }, 300);
    }
  },
};

export default materials;
