import React, { useContext } from 'react';
import Card from '../card';
import { MaterialsContext } from '../../../context/MaterialsContext';
import style from './style.module.scss';
import Modal from '../../modal';
import Button from '../../button';

export default function List() {
  const { categories, filteredData, modalContent, setModalContent, setCurrentPage, loadMore } = useContext(MaterialsContext);

  return (
    <>
      {categories.length &&
        categories.map(category => {
          return (
            filteredData &&
            filteredData.length > 0 && (
              <div
                key={category.title + '_list'}
                className={style.container}
              >
                {filteredData.some(i => i.category == category.title) && <h2 className={style.categoryTitle}>{category.title}</h2>}
                <div className={style.list}>
                  {filteredData
                    .filter(i => i.category == category.title)
                    .map(data => {
                      return (
                        <Card
                          data={data}
                          key={data.id + '_card'}
                        />
                      );
                    })}
                </div>
              </div>
            )
          );
        })}

      {loadMore && (
        <div className={style.lastPageButton}>
          <Button
            size="full"
            onClick={e => setCurrentPage(e => e + 1)}
          >
            <span className="fa fa-plus"></span>
            MOSTRAR MAIS MATERIAIS
          </Button>
        </div>
      )}

      {!filteredData ||
        (filteredData.length == 0 && (
          <>
            <p className={style.noMatch}>Nenhum resultado encontrado com os termos escolhidos, tente novamente.</p>
            <img
              src="/images/icons/nomatch.svg"
              alt="Nenhum resultado encontrado"
              className={style.noMatchImg}
            />
          </>
        ))}

      <Modal
        modalContent={modalContent}
        setModalContent={setModalContent}
      />
    </>
  );
}
