'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const leaderTerms = {
  settings: {
    warnBeforeClose: true,
    $checkbox: $('#check-terms'),
  },

  unloadPage() {
    if (s.warnBeforeClose) {
      return 'Tem certeza?';
    }
  },

  dontWarn() {
    s.warnBeforeClose = false;

    setTimeout(function() {
      s.warnBeforeClose = true;
    }, 1000);
  },

  init() {
    window.onbeforeunload = _this.unloadPage;

    $('.js-form-terms').on('submit', function(e) {
      e.preventDefault();
      _this.dontWarn();

      if (s.$checkbox.is(':checked')) {
        this.submit();
      } else {
        Swal.fire('Você precisa aceitar os termos para que sua ação seja publicada.', '', 'error');
      }
    });

    $('a:not(.js-review)').on('click', function(e) {
      e.preventDefault();

      Swal.fire({
        title: 'Tem certeza que deseja sair?',
        text: 'Caso queira realizar esse aceite mais tarde, consulte a página de MINHAS AÇÕES que essa ação estará salva, mas não publicada.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5eb245',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, sair da página',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(result => {
        _this.dontWarn();
        if (result.value) {
          window.location.href = e.currentTarget.href;
        }
        return false;
      });
    });

    $('.js-review').on('click', function() {
      _this.dontWarn();
    });
  },
};

const s = leaderTerms.settings,
  _this = leaderTerms;

export default _this;
