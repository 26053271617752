// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noI3D6X7F1IgVE6Sz9siJQ\\=\\={color:#d7171e;font-weight:600}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/title/style.module.scss"],"names":[],"mappings":"AAAA,4BAEE,aAAA,CADA,eAEF","sourcesContent":[".title {\r\n  font-weight: 600;\r\n  color: #d7171e;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "noI3D6X7F1IgVE6Sz9siJQ=="
};
export default ___CSS_LOADER_EXPORT___;
