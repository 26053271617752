'use strict';
import React, { useState } from 'react';

export default function Item({ ...props }) {
  const [opened, setOpened] = useState(false);

  const handleToggle = () => {
    setOpened(!opened);
  };

  return (
    <>
      <dt className={`c-faq__ttl ${opened ? 'active' : ''}`} onClick={() => handleToggle()}>
        {props.obj.question}
      </dt>
      <dd
        className="c-faq__text"
        dangerouslySetInnerHTML={{
          __html: props.obj.answer,
        }}
      ></dd>
    </>
  );
}
