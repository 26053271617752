'use strict';

import $ from 'jquery';
import { stateCity } from '../../modules/select';

const index = {
  settings: {
    $body: $('body'),
  },

  init() {
    stateCity();

    $('.js-open-filter').on('click', function() {
      s.$body.addClass('aside-active');
    });

    $('.js-hide-aside').on('click', function() {
      s.$body.removeClass('aside-active');
    });

    $('#form-committees').on('submit', function(e) {
      e.preventDefault();
      s.$body.removeClass('aside-active');

      var post = $(this).serializeArray();

      post._token = $('meta[name="csrf-token"]').attr('content');

      var url = '/comites/filtrar';

      $.ajax({
        type: 'GET',
        url: url,
        data: post,
        cache: false,
        dataType: 'json',
      }).then(function(data) {
        $('.c-list-cards').empty();
        $('.c-list-cards').append(data.committees.length == 0 ? (data.committees = '<b>Nenhum Comitê para essa cidade</b>') : data.committees);
      });
    });
  },
};

const s = index.settings,
  _this = index;

export default index;
