'use strict';

const gallery = {
  init() {
    const $imgItem = document.querySelectorAll('[data-gallery-item]'),
      $galleryModal = document.querySelector('[data-gallery-modal]'),
      $bgEffect = document.querySelector('[data-bg-effect]'),
      $closeModal = document.querySelector('[data-close-modal]'),
      $prevArrow = document.querySelector('[data-modal-prev]'),
      $nextArrow = document.querySelector('[data-modal-next]');

    $prevArrow.addEventListener('click', () => {
      let $current = document.querySelector('.c-gallery__item.current'),
        $prevSibling = $current.previousElementSibling,
        type = $prevSibling.getAttribute('data-type'),
        mime = $prevSibling.getAttribute('data-mime'),
        url = $prevSibling.getAttribute('data-url'),
        id = $prevSibling.getAttribute('data-id');

      $galleryModal.querySelector('img, video').remove();

      if (id == 1) {
        $prevArrow.style.opacity = '0';
        $prevArrow.style.visibility = 'hidden';
      } else if (id == $imgItem.length) {
        $nextArrow.style.opacity = '0';
        $nextArrow.style.visibility = 'hidden';
      } else {
        $prevArrow.style.opacity = '1';
        $nextArrow.style.opacity = '1';
        $prevArrow.style.visibility = 'visible';
        $nextArrow.style.visibility = 'visible';
      }

      if (type == 'image') {
        let $img = document.createElement('img');
        $img.setAttribute('src', url);
        $img.setAttribute('class', 'c-gallery-modal__img');
        $img.setAttribute('data-id', id);

        $galleryModal.appendChild($img);
      } else {
        let $video = document.createElement('video');
        $video.setAttribute('class', 'c-gallery-modal__video');
        $video.setAttribute('data-id', id);
        $video.controls = true;
        $video.autoplay = true;

        let $source = document.createElement('source');
        $source.type = mime;
        $source.src = url;
        $video.appendChild($source);

        $galleryModal.appendChild($video);
      }

      $current.classList.remove('current');
      $prevSibling.classList.add('current');
    });

    $nextArrow.addEventListener('click', () => {
      let $current = document.querySelector('.c-gallery__item.current'),
        $nextSibling = $current.nextElementSibling,
        type = $nextSibling.getAttribute('data-type'),
        mime = $nextSibling.getAttribute('data-mime'),
        url = $nextSibling.getAttribute('data-url'),
        id = $nextSibling.getAttribute('data-id');

      $galleryModal.querySelector('img, video').remove();

      if (id == 1) {
        $prevArrow.style.opacity = '0';
        $prevArrow.style.visibility = 'hidden';
      } else if (id == $imgItem.length) {
        $nextArrow.style.opacity = '0';
        $nextArrow.style.visibility = 'hidden';
      } else {
        $prevArrow.style.opacity = '1';
        $nextArrow.style.opacity = '1';
        $prevArrow.style.visibility = 'visible';
        $nextArrow.style.visibility = 'visible';
      }

      if (type == 'image') {
        let $img = document.createElement('img');
        $img.setAttribute('src', url);
        $img.setAttribute('class', 'c-gallery-modal__img');
        $img.setAttribute('data-id', id);

        $galleryModal.appendChild($img);
      } else {
        let $video = document.createElement('video');
        $video.setAttribute('class', 'c-gallery-modal__video');
        $video.setAttribute('data-id', id);
        $video.controls = true;
        $video.autoplay = true;

        let $source = document.createElement('source');
        $source.type = mime;
        $source.src = url;
        $video.appendChild($source);

        $galleryModal.appendChild($video);
      }

      $current.classList.remove('current');
      $nextSibling.classList.add('current');
    });

    if ($imgItem.length) {
      $imgItem.forEach(el => {
        el.addEventListener('click', e => {
          let type = e.currentTarget.getAttribute('data-type'),
            mime = e.currentTarget.getAttribute('data-mime'),
            url = e.currentTarget.getAttribute('data-url'),
            id = e.currentTarget.getAttribute('data-id');

          el.classList.add('current');

          if (id == 1) {
            $prevArrow.style.opacity = '0';
            $prevArrow.style.visibility = 'hidden';
          } else if (id == $imgItem.length) {
            $nextArrow.style.opacity = '0';
            $nextArrow.style.visibility = 'hidden';
          } else {
            $prevArrow.style.opacity = '1';
            $nextArrow.style.opacity = '1';
            $prevArrow.style.visibility = 'visible';
            $nextArrow.style.visibility = 'visible';
          }

          $bgEffect.classList.add('active');
          $galleryModal.classList.add('active');

          if (type == 'image') {
            let $img = document.createElement('img');
            $img.setAttribute('src', url);
            $img.setAttribute('class', 'c-gallery-modal__img');
            $img.setAttribute('data-id', id);

            $galleryModal.appendChild($img);
          } else {
            let $video = document.createElement('video');
            $video.setAttribute('class', 'c-gallery-modal__video');
            $video.setAttribute('data-id', id);
            $video.controls = true;
            $video.autoplay = true;

            let $source = document.createElement('source');
            $source.type = mime;
            $source.src = url;
            $video.appendChild($source);

            $galleryModal.appendChild($video);
          }
        });
      });

      $closeModal.addEventListener('click', () => {
        document.querySelector('.c-gallery__item.current').classList.remove('current');

        $bgEffect.classList.remove('active');
        $galleryModal.classList.remove('active');

        setTimeout(() => {
          $galleryModal.querySelector('img, video').remove();
        }, 500);
      });

      const $loadmore = document.querySelector('[data-load]');
      if ($loadmore) {
        $loadmore.addEventListener('click', () => {
          let count = 0;
          $imgItem.forEach(el => {
            if (el.classList.contains('c-gallery__item--hidden') && count < 4) {
              el.classList.remove('c-gallery__item--hidden');
              count++;
            }
          });

          let moreItems = false;
          $imgItem.forEach(el => {
            if (el.classList.contains('c-gallery__item--hidden')) {
              moreItems = true;
            }
          });

          if (!moreItems) {
            $loadmore.style.display = 'none';
          }
        });
      }
    }
  },
};

export default gallery;
