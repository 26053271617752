// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wXba22zluOjY7Q7chQqQiQ\\=\\={-moz-column-gap:30px;column-gap:30px;display:grid;grid-template-columns:repeat(1,minmax(0,1fr));grid-template-rows:max-content}@media (min-width:1024px){.wXba22zluOjY7Q7chQqQiQ\\=\\={grid-template-columns:repeat(1,minmax(0,23fr) minmax(0,77fr))}}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/materials/gridAside/style.module.scss"],"names":[],"mappings":"AAAA,4BAEE,oBAAA,CAAA,eAAA,CADA,YAAA,CAGA,6CAAA,CADA,8BAEF,CACE,0BANF,4BAQI,6DAEF,CACF","sourcesContent":[".grid {\r\n  display: grid;\r\n  column-gap: 30px;\r\n  grid-template-rows: max-content;\r\n  grid-template-columns: repeat(1, minmax(0, 1fr));\r\n\r\n  @media (min-width: 1024px) {\r\n    /* grid-template-columns: repeat(1, calc(23% - 15px) calc(77% - 15px)); */\r\n    grid-template-columns: repeat(1, minmax(0, 23fr) minmax(0, 77fr));\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "wXba22zluOjY7Q7chQqQiQ=="
};
export default ___CSS_LOADER_EXPORT___;
