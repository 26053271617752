'use strict';

import $ from 'jquery';
import 'slick-carousel';
import { userSubscribe } from '../../modules/userSubscribe';

const index = {
  settings: {
    $highlights: $('.js-highlights'),
    $cardSlider: $('.js-cards'),
    $actions: $('.js-carousel'),
    $prevArrow: '<button class="o-btn-arrow o-btn-arrow--prev o-btn-arrow--white slick-arrow slick-prev"></button>',
    $nextArrow: '<button class="o-btn-arrow o-btn-arrow--next o-btn-arrow--white slick-arrow slick-next"></button>',
  },

  highlighsSlider() {
    s.$highlights.slick({
      arrows: true,
      dots: false,
      infinite: true,
      rows: 0,
      mobileFirst: true,
      prevArrow: s.$prevArrow,
      nextArrow: s.$nextArrow,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
      ],
    });
  },

  cardsSlider() {
    s.$cardSlider.slick({
      arrows: true,
      dots: false,
      infinite: true,
      mobileFirst: true,
      prevArrow: '<button class="o-btn-arrow o-btn-arrow--prev slick-arrow slick-prev"></button>',
      nextArrow: '<button class="o-btn-arrow o-btn-arrow--next slick-arrow slick-next"></button>',
      rows: 0,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    });
  },

  actionsSlider() {
    if (s.actionsSlider) {
      s.actionsSlider.slick({
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: 'unslick',
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: true,
            },
          },
        ],
      });
    }
  },

  init() {
    _this.highlighsSlider();
    _this.cardsSlider();
    _this.actionsSlider();
    userSubscribe();
  },
};

const s = index.settings,
  _this = index;

export default _this;
