'use strict';

import challengesForm from '../../modules/challengesForm';
import { stateCity } from '../../modules/select';

const create = {
  init() {
    challengesForm.init();
    stateCity();
  },
};

const _this = create;

export default _this;
