'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const members = {
  init() {
    $(document).on('keyup', '.js-form-add-users', function(e) {
      e.preventDefault();
      $('.c-search--panel-message').removeClass('u-display--none');

      if ($(this).val().length >= 1) {
        $('.c-search--panel').removeClass('u-display--none');
      } else {
        $('.c-search--panel').addClass('u-display--none');
      }

      if ($(this).val().length >= 3) {
        $('.c-message--label').addClass('u-display--none');

        var post = {};

        post.term = $(this).val();
        post._token = $('meta[name="csrf-token"]').attr('content');
        post.committee_id = $(this).attr('data-committee');

        $.ajax({
          type: 'POST',
          url: '/pesquisa-voluntarios/' + post.term,
          data: post,
          dataType: 'json',
          success: function(data) {
            $('#js-results').html(data.html);
          },
        });
      } else {
        $('#new-guest').addClass('u-display--none');
        $('.c-message--label').removeClass('u-display--none');
        $('#js-results').html('');
      }
    });

    $(document).on('click', '.js-add-committee', function(e) {
      let $this = $(this);
      e.preventDefault();

      var post = {};

      post._token = $('meta[name="csrf-token"]').attr('content');

      var url = '/comites/' + $(this).data('id') + '/integrantes/adicionar/' + $(this).data('participant');

      $.ajax({
        type: 'POST',
        url: url,
        data: post,
        dataType: 'json',
        success: function(data) {
          if (data.type === 'success') {
            $('#counter').html(parseInt($('#counter').text()) + 1);
            $('.js-form-add-users').val('');
            $('.c-search--panel').addClass('u-display--none');
            $this.closest('li').remove();
            $('#list-tab-1').append(data.html);
            $('#list-tab-2').append(data.html);
          } else {
            Swal.fire(data.title, data.message, data.type);
          }
        },
      });

      return false;
    });

    $(document).on('click', '.js-delete-user', function(e) {
      e.preventDefault();
      let data_id = $(this).attr('data-id');

      Swal.fire({
        title: 'Excluir registro?',
        text: 'Tem certeza que deseja remover este registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir registro!',
        cancelButtonText: 'Não excluir',
      }).then(result => {
        if (result.value) {
          var token = $('meta[name="csrf-token"]').attr('content');

          $.ajax({
            headers: {
              'X-CSRF-TOKEN': token,
            },
            url: $(this).attr('data-url'),
            method: 'DELETE',
            success: function(data) {
              Swal.fire(data.title, data.message, data.type);

              if (data.type == 'success') {
                var rows = $('.c-list-users__item[data-id="' + data_id + '"]');
                rows.remove();
                $('#counter').html(parseInt($('#counter').text()) - 1);
              }
            },
          });
        }
      });
    });
  },
};

export default members;
