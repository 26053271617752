'use strict';

import croppie from 'croppie';
import $ from 'jquery';

const croppieModal = {
  settings: {
    $customCroppie: $('#customCroppie'),
    $fileLink: $('.js-file-link'),
    $cancelBtn: $('.js-crop-cancel'),
    $saveBtn: $('.js-crop'),
    $uploadCrop: document.getElementById('upload-crop'),
  },

  init() {
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    });

    s.$fileLink.on('click', function(e) {
      e.preventDefault();
      s.$customCroppie.trigger('click');
    });

    let imgCroppie;

    s.$customCroppie.on('change', function() {
      var reader = new FileReader();
      $('.js-croppie-btns').show();
      reader.onload = function(e) {
        imgCroppie = new croppie(s.$uploadCrop, {
          enableExif: true,
          viewport: {
            width: 180,
            height: 180,
            type: 'circle',
          },
          boundary: {
            width: 220,
            height: 220,
          },
        });

        imgCroppie
          .bind({
            url: e.target.result,
          })
          .then(function() {
            document.getElementById('modal-crop').classList.add('is-active');
          });
      };
      reader.readAsDataURL(this.files[0]);
    });

    s.$cancelBtn.on('click', function() {
      document.getElementById('modal-crop').classList.remove('is-active');

      setTimeout(() => {
        $('#upload-crop').croppie('destroy');
      }, 300);
    });

    s.$saveBtn.on('click', function(e) {
      e.preventDefault();

      let url = $(this).data('action');
      if (imgCroppie) {
        imgCroppie
          .result({
            type: 'canvas',
            size: 'viewport',
          })
          .then(function(resp) {
            $.ajax({
              url: url,
              type: 'POST',
              data: { cover: resp },
              success: function(data) {
                document.getElementById('modal-crop').classList.remove('is-active');

                setTimeout(() => {
                  imgCroppie.destroy();
                }, 300);

                $('.js-upload-img').remove();

                let html = '<img src="' + resp + '" class="js-upload-img" />';
                $('#js-upload-img-wrapper').html(html);
                $('#cover').attr('value', data.cover);
              },
            });
          });
      }
    });
  },
};

const s = croppieModal.settings;

export default croppieModal;
