import React from 'react';

import style from './style.module.scss';
import cn from 'classnames';

function Title({ size = 24, mb = 0, children }) {
  return (
    <>
      <h1
        className={cn(style.title)}
        style={{ fontSize: size, marginBottom: mb }}
      >
        {children}
      </h1>
    </>
  );
}

export default Title;
