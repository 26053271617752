import React, { useContext, useState, useEffect } from 'react';
import { MaterialsContext } from '../../../context/MaterialsContext';
import style from './style.module.scss';
import cn from 'classnames';

function Search() {
  const { originalData, setFilteredData, setShowHighlights, setFilter, setLoadMore } = useContext(MaterialsContext);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function loadData() {
      try {
        const res = await fetch(`/api/v1/materiais/?filter[keyword]=${search}`);
        const data = await res.json();
        console.log(data.data);
        setFilteredData(data.data);
      } catch (error) {
        console.log(error);
      }
    }

    let interval;
    if (search.length) {
      interval = setTimeout(() => {
        loadData();
        setShowHighlights(false);
        setLoadMore(false);
      }, 500);
    } else {
      setFilteredData(originalData);
      setShowHighlights(true);
      setLoadMore(true);
    }
    return () => clearTimeout(interval);
  }, [search]);

  return (
    <div className={style.search}>
      <div className={style.searchContainer}>
        <h3 className={style.title}>Qual material você está procurando?</h3>

        <input
          className={style.input}
          type="text"
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder="Digite palavra-chave"
          id="search"
        />

        <button
          className={cn(style.button, { [style.buttonActive]: search })}
          onClick={e => setSearch('')}
        >
          LIMPAR BUSCA
        </button>
      </div>

      <div className={style.filterWrapper}>
        <span onClick={e => setFilter(true)}>Filtrar materiais</span>
      </div>
    </div>
  );
}

export default Search;
