'use strict';

import React from 'react';
import Title from '../../react/components/title';
import Subtitle from '../../react/components/subtitle';
import GridAside from '../../react/components/materials/gridAside';
import Aside from '../../react/components/materials/aside';
import List from '../../react/components/materials/list';
import Categories from '../../react/components/materials/categories';
import Search from '../../react/components/materials/search';
import Highlights from '../../react/components/materials/highlights';
import { MaterialsStorage } from '../../react/context/MaterialsContext';
import style from './style.module.scss';

export default function Materials() {
  return (
    <MaterialsStorage>
      <div className="o-wrapper u-p-t-30">
        <Title>Materiais</Title>
        <Subtitle>Conteúdos para você entender tudo sobre o Programa de Voluntariado Corporativo.</Subtitle>
      </div>
      <GridAside className={style.gridAside}>
        <Aside>
          <Categories />
        </Aside>
        <div>
          <Search />
          <Highlights />
          <List />
        </div>
      </GridAside>
    </MaterialsStorage>
  );
}
