'use strict';

import $ from 'jquery';
import 'select2';

const personal = {
  init() {
    $('.js-areas').select2({
      placeholder: 'Selecione sua área',
      theme: 'classic',
    });
  },
};

export default personal;
