'use strict';

import { stateCity } from '../../modules/select';

const state = {
  init() {
    stateCity();
  },
};

export default state;
