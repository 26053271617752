import ReactDOM from 'react-dom';
import React from 'react';

import 'bootstrap';

// Utils
import match from './utils/match';

// Routes
import common from './routes/common';
import appIndex from './routes/app';
import appAccessCode from './routes/app/accessCode';
import register from './routes/register/common';
import registerShowRegistrationForm from './routes/register/showRegistrationForm';
import profileTerms from './routes/profiles/terms';
import committees from './routes/committees/common';
import committeesIndex from './routes/committees/';
import committeesShow from './routes/committees/show';
import committeesMembers from './routes/committees/members';
import profilesCover from './routes/profiles/cover';
import profilesPersonal from './routes/profiles/personal';
import profilesPassword from './routes/profiles/password';
import profilesState from './routes/profiles/state';
import challengesIndex from './routes/challenges/';
import challengesCreate from './routes/challenges/create';
import challengesEdit from './routes/challenges/edit';
import challengesShow from './routes/challenges/show';
import challengesLeaderTerms from './routes/challenges/leaderTerms';
import challengesGallery from './routes/challenges/gallery';
import challengesMaterials from './routes/challenges/materials';
import { challengesMine } from './routes/challenges/mine';
import presencesIndex from './routes/presences/';
import presencesActivities from './routes/presences/activities';
import presencesParticipants from './routes/presences/participants';
import presencesGallery from './routes/presences/gallery';
import presencesSavedreport from './routes/presences/savedreport';
import forgotpassword from './routes/forgotpassword';
import habilitiesCommon from './routes/habilities/common';
import habilitiesIndex from './routes/habilities/index';
import subscribesShow from './routes/subscribes/show';

// React
import Faq from './routes/faq';
import Materials from './routes/materials';
import Blog from './routes/blog';

var APP = {
  common: {
    init() {
      common.init();
    },
  },

  app: {
    index() {
      appIndex.init();
    },

    accessCode() {
      appAccessCode.init();
    },
  },

  register: {
    init() {
      register.init();
    },

    showRegistrationForm() {
      registerShowRegistrationForm.init();
    },
  },

  committees: {
    index() {
      committeesIndex.init();
    },

    init() {
      committees.init();
    },

    show() {
      committeesShow.init();
    },

    members() {
      committeesMembers.init();
    },
  },

  profiles: {
    cover() {
      profilesCover.init();
    },

    personal() {
      profilesPersonal.init();
    },

    password() {
      profilesPassword.init();
    },

    state() {
      profilesState.init();
    },

    terms() {
      profileTerms.init();
    },
  },

  challenges: {
    index() {
      challengesIndex.init();
    },

    search() {
      challengesIndex.init();
    },

    create() {
      challengesCreate.init();
    },

    edit() {
      challengesEdit.init();
    },

    leaderTerms() {
      challengesLeaderTerms.init();
    },

    show() {
      challengesShow.init();
    },

    gallery() {
      challengesGallery.init();
    },

    materials() {
      challengesMaterials.init();
    },

    mine() {
      challengesMine();
    },
  },

  presences: {
    index() {
      presencesIndex.init();
    },

    activities() {
      presencesActivities.init();
    },

    participants() {
      presencesParticipants.init();
    },

    gallery() {
      presencesGallery.init();
    },

    savedreport() {
      presencesSavedreport.init();
    },
  },

  blogs: {
    index() {
      ReactDOM.render(<Blog />, document.getElementById('app'));
    },
  },

  materials: {
    index() {
      ReactDOM.render(<Materials />, document.getElementById('app'));
    },
  },

  faqs: {
    index() {
      ReactDOM.render(<Faq />, document.getElementById('app'));
    },
  },

  forgotpassword: {
    init() {
      forgotpassword.init();
    },
  },

  habilities: {
    common() {
      habilitiesCommon.init();
    },

    index() {
      habilitiesIndex.init();
    },
  },

  subscribes: {
    show() {
      subscribesShow.init();
    },
  },
};

document.addEventListener('DOMContentLoaded', match.init(APP));
