import React from 'react';
import Router from '../../react/components/blog/router';
import { BlogStorage } from '../../react/context/BlogContext';

function Blog() {
  return (
    <section className="o-section">
      <div className="o-wrapper">
        <BlogStorage>
          <Router />
        </BlogStorage>
      </div>
    </section>
  );
}

export default Blog;
