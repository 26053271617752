import React from 'react';
import { BlogContext } from '../../context/BlogContext';

function Card({ data }) {
  const { setNewsActive } = React.useContext(BlogContext);

  function showNotice(e, id) {
    e.preventDefault();
    setNewsActive(id);
  }

  return (
    <div className="c-card-blog">
      <div className="c-card-blog__wrapper">
        <a
          href="#"
          title={data.title}
          onClick={e => showNotice(e, data.id)}
        >
          <img
            src={data.cover}
            className="c-card-blog__image"
            alt={data.title}
          />
        </a>
      </div>

      <div>
        <a
          href="#"
          title={data.title}
          onClick={e => showNotice(e, data.id)}
        >
          <p className="c-card-blog__date">{data.published_at}</p>
          <h2 className="c-card-blog__title">{data.title}</h2>
          <p className="c-card-blog__resume">{data.resume}</p>
        </a>
      </div>
    </div>
  );
}

export default Card;
