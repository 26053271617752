'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';
import 'select2';
import { stateCity } from '../../modules/select';

const showRegistrationForm = {
  settings: {
    $selectState: $('.js-state-filter'),
    $selectCity: $('.js-cities'),
    $selectInvitedBy: $('#who_invited'),
    $selectHabilities: $('.js-habilities'),
    $selectUnities: $('.js-unities'),
    $selectAreas: $('.js-areas'),
    $selectFuncional: $('.js-funcional'),
  },

  initSelect() {
    stateCity();

    if (s.$selectUnities) {
      s.$selectUnities.select2({
        placeholder: 'Selecione sua unidade',
        theme: 'classic',
        class: 'js-unities',
      });
    }

    if (s.$selectHabilities) {
      s.$selectHabilities
        .select2({
          placeholder: 'Selecione sua habilidade',
          theme: 'classic',
          multiple: true,
        })
        .on('select2:select', function (e) {
          if (e.params.data.text === 'Outra habilidade não listada') {
            $('#other').removeClass('u-display--none');
          }
        })
        .on('select2:unselect', function (e) {
          if (e.params.data.text === 'Outra habilidade não listada') {
            $('#other').addClass('u-display--none');
            $('#other_hability').val('');
          }
        });
    }

    if (s.selectFuncional) {
      s.selectFuncional.select2({
        placeholder: 'Selecione sua categoria funcional',
        theme: 'classic',
      });
    }

    if (s.$selectAreas) {
      s.$selectAreas.select2({
        placeholder: 'Informe a sua área',
        theme: 'classic',
      });
    }
  },

  nextStep() {
    $('.continue').on('click', function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      if (currentSlide == 'first') {
        if (
          $("input[name='volunteer_type']:checked").val() == null ||
          $("input[name='name']").val() == ''
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide === 'state') {
        if (s.$selectState.val() === '' || s.$selectCity.val() === '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');

          return false;
        }

        if ($("input[name='volunteer_type']:checked").val() == 'external') {
          nextSlide = 'password';
        } else {
          nextSlide = 'rede';
        }
      }

      if (currentSlide === 'rede') {
        if (
          ($.inArray($("input[name='volunteer_type']:checked").val(), ['employee', 'trainee']) !== -1 &&
            ($("input[name='enrollment']").val() === '' || $('#js-areas').val() === '')) ||
          ($.inArray($("input[name='volunteer_type']:checked").val(), ['provider']) !== -1 && $("input[name='enrollment']").val() === '')
        ) {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      if (currentSlide === 'contact') {
        if ($("input[name='email']").val() == '' || $("input[name='email']").val().toLowerCase().indexOf('@') === -1) {
          Swal.fire('Atenção!', 'Preencha o e-mail corretamente!', 'error');
          return false;
        }
      }

      if (currentSlide === 'habilities') {
        if (s.$selectHabilities.val() === '') {
          Swal.fire('Atenção!', 'Preencha os campos corretamente', 'error');
          return false;
        }
      }

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });
  },

  prevStep() {
    $('.back').on('click', function (e) {
      e.preventDefault();

      let currentSlide = $(this).attr('data-current');
      let nextSlide = $(this).attr('data-next');

      if (nextSlide == 'rede') {
        if (
          $("input[name='volunteer_type']:checked").val() == 'external' ||
          $("input[name='volunteer_type']:checked").val() == 'provider'
        ) {
          nextSlide = 'state';
        } else {
          nextSlide = 'rede';
        }
      }

      $('#' + currentSlide).removeClass('active');
      $('#' + nextSlide).addClass('active');
    });
  },

  bindActions() {
    $('input[name="volunteer_type"]').on('change', function () {
      $('.js-form-email').attr('placeholder', '');
      $('#area').hide();
      $('#who_invited_block').hide();
    });

    $('.js-rede').on('change', function () {
      if ($("input[name='is_rede']:checked").val() == '1') {
        $('#is_rede_options').removeClass('u-display--none');
        $('#is_regional_options').removeClass('u-display--none');
      } else {
        $('#is_rede_options').addClass('u-display--none');
        $('#is_regional_options').addClass('u-display--none');
      }
    });

    $('.js-form-cpf').on('change keyup paste', function (e) {
      e.preventDefault();

      var cpf = $(this).val();
      if (cpf != '') {
        var validacpf = /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/;

        if (validacpf.test(cpf)) {
          getCpf(cpf);
        } else {
          $('#cpf-error').text('');
        }
      }
    });

    function getCpf(cpf) {
      var url = '/check-cpf/' + cpf;

      $.ajax({
        type: 'get',
        url: url,
        dataType: 'json',
        data: {},
        success: function (response) {
          if (!response.success) {
            $('#cpf-error').text(response.message);
          }
        },
        error: function (jqXHR, status, error) {
          console.log(error);
        },
      });
    }

    // $('.js-form-email').on('change keyup paste', function (e) {
    //   e.preventDefault();

    //   var email = $(this).val();
    //   if (email != '') {
    //     getEmail(email);
    //   }
    // });

    function getEmail(email) {
      var url = '/check-email/' + email;

      $.ajax({
        type: 'get',
        url: url,
        dataType: 'json',
        data: {},
        success: function (response) {
          if (!response.success) {
            $('#email-error').text(response.message);
          } else {
            $('#email-error').text('');
          }
        },
        error: function (jqXHR, status, error) {
          console.log(error);
        },
      });
    }

    $(document).on('click', '#btn-register', function (e) {
      e.preventDefault();

      if (!_this.complexPassword()) {
        Swal.fire('Preencha uma senha válida!', '', 'error');
      } else {
        let checkLength = $('.js-check').length;

        if ($('.js-check:checked').length === checkLength) {
          $('#form-signin').submit();
        } else {
          Swal.fire('Você deve aceitar os termos para continuar!', '', 'error');
        }
      }

      return false;
    });
  },

  complexPassword() {
    var password = document.getElementById('password_user');

    if (password.length < 8) {
      password.focus();
      return false;
    } else if (!/^(?=.*[A-Z])(?=.*[!#@$%&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(password.value)) {
      password.focus();
      return false;
    }
    return true;
  },

  init() {
    _this.initSelect();
    _this.nextStep();
    _this.prevStep();
    _this.bindActions();
  },
};

const s = showRegistrationForm.settings,
  _this = showRegistrationForm;

export default _this;
