'use strict';

import croppieModal from '../../modules/croppieModal';

const cover = {
  init() {
    croppieModal.init();
  },
};

const _this = cover;

export default _this;
