'use strict';

var match = {
  exec: function(APP, controller, action) {
    var namespace = APP;
    action = action === undefined ? 'init' : action;

    if (controller !== '' && namespace[controller] && typeof namespace[controller][action] == 'function') {
      namespace[controller][action]();
    }
  },

  init: function(APP) {
    var body = document.body;
    var controller = body.getAttribute('data-controller');
    var action = body.getAttribute('data-action');

    match.exec(APP, 'common');
    match.exec(APP, controller);
    match.exec(APP, controller, 'common');
    match.exec(APP, controller, action);
  },
};

export default match;
