'use strict';

import $ from 'jquery';
import Swal from 'sweetalert2';

const loginTerms = {
  settings: {
    checkLength: document.querySelectorAll('.js-check').length,
    $form: document.getElementById('form-terms'),
  },
  bindActions() {
    s.$form.addEventListener('submit', e => {
      let checkedLength = document.querySelectorAll('.js-check:checked').length;

      if (checkedLength !== s.checkLength) {
        e.preventDefault();
        Swal.fire('Você deve aceitar os termos para continuar!', '', 'error');
      }
    });
  },

  init() {
    _this.bindActions();
  },
};

const s = loginTerms.settings,
  _this = loginTerms;

export default loginTerms;
