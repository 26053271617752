'use strict';

import $ from 'jquery';
import mask from 'jquery-mask-plugin';
import Swal from 'sweetalert2';

const common = {
  settings: {
    $selectSuper: $('.js-superintendent'),
    $selectRegional: $('.js-regional'),
    $lang: document.documentElement.lang,
  },

  init() {
    const $modalTrigger = document.getElementById('modal-trigger');

    if ($modalTrigger) {
      const title = $modalTrigger.getAttribute('data-title'),
        type = $modalTrigger.getAttribute('data-type'),
        text = $modalTrigger.getAttribute('data-text');

      Swal.fire({ title: `${title ? title : ''}`, text: `${text ? text : ''}`, icon: `${type ? type : ''}` });
    }

    $('.js-dropup-trigger').on('click', function () {
      this.parentElement.classList.toggle('active');
    });

    $(document).on('click', function () {
      $('.c-dropup').removeClass('active');
    });

    $('.c-dropup').on('click', function (e) {
      e.stopPropagation();
    });

    // Email Protective SPAM Solution
    const $mailContainer = document.querySelectorAll('[data-mail-creator]');

    if ($mailContainer.length) {
      $mailContainer.forEach(el => {
        let $mail = document.createElement('a');
        let arrMail = el.getAttribute('data-mail-creator').split(',');

        if (arrMail.length == 3) {
          let label = arrMail[0].replace(/\s/g, '') + '@' + arrMail[1].replace(/\s/g, '') + arrMail[2].replace(/\s/g, '');
          $mail.setAttribute('href', 'ma' + 'il' + 'to:' + label);
          $mail.textContent = label;
          el.appendChild($mail);
        }
      });
    }

    //Policy Cookies Bar
    if (typeof localStorage.policyCookies == 'undefined' || localStorage.policyCookies == '0') {
      localStorage.policyCookies = '0';
      document.body.classList.add('show-policy');
    } else {
      let expire = new Date(localStorage.policyCookiesExpire),
        today = new Date();

      if (today >= expire) {
        localStorage.policyCookies = 0;
        document.body.classList.add('show-policy');
      }
    }

    document.querySelector('.js-btn-cookies').addEventListener('click', e => {
      e.preventDefault();
      let date = new Date(new Date().setDate(new Date().getDate() + 30));
      localStorage.policyCookies = '1';
      localStorage.policyCookiesExpire = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      document.body.classList.remove('show-policy');
    });

    //Toggle Menu
    const $toggleNav = document.querySelector('.js-toggle-menu'),
      $body = document.body;

    $toggleNav.addEventListener('click', e => {
      e.preventDefault();
      $body.classList.toggle('nav-active');
      if ($body.classList.contains('nav-active')) {
        e.target.innerHTML = e.target.getAttribute('data-close');
      } else {
        e.target.innerHTML = e.target.getAttribute('data-open');
      }
    });

    //Delete register
    $(document).on('click', '.js-delete', function (e) {
      e.preventDefault();
      let data_id = $(this).attr('data-id');

      Swal.fire({
        title: 'Excluir registro?',
        text: 'Tem certeza que deseja remover este registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir registro!',
        cancelButtonText: 'Não excluir',
      }).then(result => {
        if (result.value) {
          var token = $('meta[name="csrf-token"]').attr('content');

          $.ajax({
            headers: {
              'X-CSRF-TOKEN': token,
            },
            url: $(this).attr('data-url'),
            method: 'DELETE',
            success: function (data) {
              Swal.fire(data.title, data.message, data.type);

              if (data.type == 'success') {
                var row = document.getElementById(data_id);
                row.parentNode.removeChild(row);
              }
            },
          });
        }
      });
    });

    //Custom delete register
    $(document).on('click', '.js-delete-custom', function (e) {
      e.preventDefault();
      let data_id = $(this).attr('data-id');
      let data_title = $(this).attr('data-message-title');
      let data_content = $(this).attr('data-message-content');

      Swal.fire({
        title: data_title,
        text: data_content,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não excluir',
      }).then(result => {
        if (result.value) {
          var token = $('meta[name="csrf-token"]').attr('content');

          $.ajax({
            headers: {
              'X-CSRF-TOKEN': token,
            },
            url: $(this).attr('data-url'),
            method: 'DELETE',
            success: function (data) {
              Swal.fire(data.title, data.message, data.type);

              if (data.type == 'success') {
                var row = document.getElementById(data_id);
                row.parentNode.removeChild(row);

                if ($('.js-counter').length && data.count) {
                  $('.js-counter').html(data.count);
                }

                if ($('.js-free-vacancies').length) {
                  var num = $('.js-free-vacancies').html();
                  $('.js-free-vacancies').html(parseInt(num) + 1);
                }
              }
            },
          });
        }
      });
    });

    $.fn.inputFilter = function (inputFilter) {
      return this.on('input keydown keyup mousedown mouseup select contextmenu drop', function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    };

    $('.js-number').inputFilter(function (value) {
      return /^\d*$/.test(value);
    });

    $('.file-input').on('change', function () {
      $('#label_' + this.name).text(this.files[0].name);
    });

    s.$selectSuper.on('change', function (e) {
      e.preventDefault();

      var post = {};

      s.$selectRegional.empty();
      s.$selectRegional.append($('<option></option>').attr('value', '').text('Carregando...'));

      post.id = s.$selectSuper.val();
      post._token = $('meta[name="csrf-token"]').attr('content');

      var url = '/busca-regionais/' + post.id;

      $.ajax({
        type: 'GET',
        url: url,
        data: post,
        cache: false,
        dataType: 'json',
        success: function (data) {
          if (data) {
            s.$selectRegional.empty();

            s.$selectRegional.append($('<option></option>').attr('value', '0').text('Selecione sua regional'));
            $.each(data, function (index, element) {
              s.$selectRegional.append($('<option></option>').attr('value', index).text(element));
            });
          }
        },
      });
    });

    $(document).on('click', '.js-change-tab', function (e) {
      e.preventDefault();

      $('.tab').each(function () {
        $(this).removeClass('active');
      });

      $('#' + $(this).data('tab')).addClass('active');
    });

    const maskEl = {
      $cnpj: [$('.js-form-cnpj'), '99.999.999/9999-99'],
      $cpf: [$('.js-form-cpf'), '999.999.999-99'],
      $date: [$('.js-form-date'), '99/99/9999'],
      $hour: [$('.js-form-hour'), '99:99'],
      $ddd: [$('.js-form-ddd'), '(99)'],
      $phone: [$('.js-form-phone'), '(99) 99999.9999'],
      $comercialPhone: [$('.js-form-phone-commercial'), '(99) 9999.9999'],
      $number: [$('.js-number'), '999999'],
    };

    Object.keys(maskEl).forEach(el => {
      if (maskEl[el][0].length > 0) {
        maskEl[el][0].mask(maskEl[el][1]);
        if (typeof maskEl[el][0].attr('value') !== 'undefined') {
          if (maskEl[el][0].attr('value').length <= 0) {
            setTimeout(() => {
              maskEl[el][0].val('');
            }, 600);
          }
        }
      }
    });

    $(document).on('click', '.js-send-email', function (e) {
      e.preventDefault();

      $('#tab-send-email').removeClass('u-display--none');
    });
    $(document).on('click', '.js-cancel-email', function (e) {
      e.preventDefault();

      $('#tab-send-email').addClass('u-display--none');
    });

    $(document).on('click', '[data-modal-close]', function () {
      $(this).closest('.c-modal').removeClass('is-active');
      $('#modal-form').remove();
    });

    $('.js-modal-warning-logged').on('click', function (e) {
      e.preventDefault();
      Swal.fire({
        html:
          '<h3 class="o-ttl">' +
          $(this).attr('data-title') +
          '</h3>' +
          '<p>Você precisa realizar o seu login na plataforma para visualizar essas informações.</p>',
        padding: '25px 15px',
        position: 'top',
        showConfirmButton: false,
        showClass: {
          popup: 'c-new-swal--popup',
        },
        customClass: 'c-new-swal',
      });
    });

    let grecaptchaKeyMeta = document.querySelector("meta[name='grecaptcha-key']");
    let grecaptchaKey = grecaptchaKeyMeta.getAttribute('content');

    grecaptcha.ready(function () {
      let forms = document.querySelectorAll('form[data-grecaptcha-action]');

      Array.from(forms).forEach(function (form) {
        form.onsubmit = e => {
          e.preventDefault();

          let grecaptchaAction = form.getAttribute('data-grecaptcha-action');

          grecaptcha.execute(grecaptchaKey, { action: grecaptchaAction }).then(token => {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'grecaptcha';
            input.value = token;

            form.append(input);

            form.submit();
          });
        };
      });
    });
  },
};

const s = common.settings,
  _this = common;

export default _this;
