// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xKyfc\\+krD9oluYat9QV6qg\\=\\={margin:0 auto;max-width:1200px;padding:0 15px}.T7iQcYKBWMKG\\+kcXicbpQQ\\=\\={display:grid;gap:15px;grid-template-columns:repeat(1,1fr)}@media (min-width:1024px){.T7iQcYKBWMKG\\+kcXicbpQQ\\=\\={grid-template-columns:repeat(3,1fr)}}.h2PyLHE\\+GhTmEkDAATaZQg\\=\\={color:#333;font-size:20px;font-weight:700;margin-bottom:25px;margin-top:45px}.gQbWF2H0eXWoJAI6Pz6RCA\\=\\={font-size:16px;font-weight:700;margin:25px 0;padding:0 15px}@media (min-width:1024px){.gQbWF2H0eXWoJAI6Pz6RCA\\=\\={margin:50px 0}}.C\\+BF1Oqkj-2i1r8--JeOug\\=\\={display:block;margin:0 auto;max-width:220px;width:100%}.YDtE\\+S3yBKxT9CeF-lol4g\\=\\={padding:30px 15% 10px}", "",{"version":3,"sources":["webpack://./resources/assets/js/react/components/materials/list/style.module.scss"],"names":[],"mappings":"AAAA,6BAGE,aAAA,CADA,gBAAA,CADA,cAGF,CAEA,6BACE,YAAA,CAEA,QAAA,CADA,mCAEF,CACE,0BALF,6BAMI,mCAEF,CACF,CACA,6BAGE,UAAA,CADA,cAAA,CADA,eAAA,CAGA,kBAAA,CACA,eAEF,CACA,4BACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,cAEF,CAAE,0BANF,4BAOI,aAGF,CACF,CAAA,6BACE,aAAA,CACA,aAAA,CAEA,eAAA,CADA,UAIF,CAAA,6BACE,qBAGF","sourcesContent":[".container {\r\n  padding: 0 15px;\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.list {\r\n  display: grid;\r\n  grid-template-columns: repeat(1, 1fr);\r\n  gap: 15px;\r\n\r\n  @media (min-width: 1024px) {\r\n    grid-template-columns: repeat(3, 1fr);\r\n  }\r\n}\r\n\r\n.categoryTitle {\r\n  font-weight: 700;\r\n  font-size: 20px;\r\n  color: #333333;\r\n  margin-bottom: 25px;\r\n  margin-top: 45px;\r\n}\r\n\r\n.noMatch {\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n  margin: 25px 0;\r\n  padding: 0 15px;\r\n\r\n  @media (min-width: 1024px) {\r\n    margin: 50px 0;\r\n  }\r\n}\r\n\r\n.noMatchImg {\r\n  display: block;\r\n  margin: 0 auto;\r\n  width: 100%;\r\n  max-width: 220px;\r\n}\r\n\r\n.lastPageButton {\r\n  padding: 30px 15% 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "xKyfc+krD9oluYat9QV6qg==",
	"list": "T7iQcYKBWMKG+kcXicbpQQ==",
	"categoryTitle": "h2PyLHE+GhTmEkDAATaZQg==",
	"noMatch": "gQbWF2H0eXWoJAI6Pz6RCA==",
	"noMatchImg": "C+BF1Oqkj-2i1r8--JeOug==",
	"lastPageButton": "YDtE+S3yBKxT9CeF-lol4g=="
};
export default ___CSS_LOADER_EXPORT___;
