'use strict';

import $ from 'jquery';
import { stateCity, unity } from '../../modules/select';

const common = {
  init() {
    stateCity();
    unity();

    let habilities = JSON.parse(sessionStorage.habilities);
    let hability_selected = JSON.parse(sessionStorage.hability_selected);

    let unities = JSON.parse(sessionStorage.unities);
    let unity_selected = JSON.parse(sessionStorage.unity_selected);

    $('#js-habilities').select2({
      placeholder: 'Selecione uma categoria',
      theme: 'classic',
      data: habilities,
      multiple: true,
    });

    $('#js-habilities').val(hability_selected);
    $('#js-habilities').trigger('change');
  },
};

export default common;
