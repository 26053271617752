import React from 'react';

export const BlogContext = React.createContext();

export const BlogStorage = ({ children }) => {
  const [dataList, setDataList] = React.useState([]);
  const [dataNews, setDataNews] = React.useState([]);
  const [newsActive, setNewsActive] = React.useState(false);

  React.useEffect(() => {
    let id = window.location.pathname.split('/')[2];

    id ? setNewsActive(+id) : loadListData();
  }, []);

  React.useEffect(() => {
    if (Number.isInteger(newsActive)) {
      fetchNews();
    } else {
      if (!dataList.length) {
        loadListData();
      }
      window.history.pushState({}, null, `/blog/`);
    }
  }, [newsActive]);

  async function fetchNews() {
    try {
      const response = await fetch(`/api/v1/blog/${newsActive}`);
      const data = await response.json();
      setDataNews(data);
      window.history.pushState({}, null, `/blog/${newsActive}/${slugGenerate(data.title)}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadListData() {
    try {
      const response = await fetch('/api/v1/blog');
      const data = await response.json();
      setDataList(data);
    } catch (error) {
      console.log(error);
    }
  }

  function urlVerify() {
    let id = window.location.pathname.split('/')[2];

    id && setNewsActive(+id);
  }

  function slugGenerate(string) {
    return string
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  return (
    <BlogContext.Provider
      value={{ dataList, dataNews, setDataNews, newsActive, setNewsActive, urlVerify }}
    >
      {children}
    </BlogContext.Provider>
  );
};
